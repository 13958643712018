<template>
	<div>
        <div class="layoutContent">
            <div class="Grid_root__iRfoa grid-style-17">
                <div class="Grid_a__vY7M8">
                    <h2 class="typography_h1 text-quaternary">
                        Earn Rewards Even After Selling
                    </h2>
                    <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                    <p class="fs-21 text-tertiary">
                        Invest in top cryptocurrencies and earn rewards that continue even after selling. Join 68,000+ users creating lasting wealth together.
                    </p>
                </div>
            </div>
        </div>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>