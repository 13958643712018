<template>
	<div class="markdown prose">
        <p>The best traders don’t aim to be right all the time—they aim to <span class="text-black01 font-weight-bold">win big when they are.</span></p>
        <p >But traditional trading systems hold you back:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Fees cut into your margins.</span></li>
            <li><span class="text-black01 font-weight-bold">Predictions turn into gambling.</span></li>
            <li><span class="text-black01 font-weight-bold">Market inefficiencies favor institutions, not you.</span></li>
        </ul>
        <p >With <span class="text-black01 font-weight-bold">Untrading</span>, you eliminate the noise:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">No commissions. </span>No hidden costs—just pure profit.</li>
            <li><span class="text-black01 font-weight-bold">No reliance on market predictions. </span>Focus on asymmetric opportunities.</li>
            <li><span class="text-black01 font-weight-bold">Maximum upside, minimum friction. </span>When you win, you win <span class="text-black01 font-weight-bold">fully.</span></li>
        </ul>
        <p >Because <span class="text-black01 font-weight-bold">trading isn’t about perfection—it’s about capitalizing at the right moment.</span></p>
    </div>
</template>