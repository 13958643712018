<template>
    <div class="lexend-deca">
        <v-row no-gutters align="start" justify="center">
            <v-col cols="12" sm="12" md="9" lg="9" xl="9" xxl="5" class="px-0 mt-8 show-tablet show-laptop">
                <v-img contain class="cryptoImg r-p-l-0" aspect-ratio="1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/84b99fec-b8ed-4802-ac19-ee08c6e1ab00/public"></v-img>
                <div class="d-flex pl-6">
                    <div v-show="this.darkTheme == 0">
                        <Logo type="unCryptos" app="unCryptos" color="light" responsive="pc" size="150"></Logo>
                    </div>
                    <div v-show="this.darkTheme == 1" >
                        <Logo type="unCryptos" app="unCryptos" color="dark" responsive="pc" size="150"></Logo>
                    </div>
                </div>
                <div>
                    <div class="Spacer_root__uoSvA" style="--height:32px"></div>
                    <div class="ga-2 d-flex flex-column r-p-x-6">
                        <h3 class="fs-21-bold text-secondary">
                            No Fees, No Predictions
                        </h3>
                        <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                        <p class="text-tertiary fs-17">
                            Grow your profits through participation with Future Rewards.
                        </p>
                    </div>
                    <div class="Spacer_root__uoSvA" style="--height:32px"></div>
                    <div class="ga-2 d-flex flex-column r-p-x-6">
                        <h3 class="fs-21-bold text-secondary">
                            Full Control
                        </h3>
                        
                        <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                        <p class="text-tertiary fs-17 gradient-underline-hover">
                            Keep your crypto in your wallet while earning risk-free "<a href="https://5173.foundation/" target="_blank" class="pointer text-tertiary del-underline link-underline-hover"><span class="gradient-text-hover">variable annuity</span></a>" rewards through Flows.
                        </p>
                    </div>
                    <div class="Spacer_root__uoSvA" style="--height:64px"></div>
                    <div>
                        <Market></Market>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="7" xl="8" xxl="5" class="px-0 mt-8 hide-tablet">
                <v-img contain width="1200" height="914" class="cryptoImg r-p-l-0" aspect-ratio="1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/84b99fec-b8ed-4802-ac19-ee08c6e1ab00/public"></v-img>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="5" xl="4" xxl="3" class="d-flex flex-column px-0 c_inside mt-16 hide-tablet">
                <div>
                    <div v-show="this.darkTheme == 0">
                        <Logo type="unCryptos" app="unCryptos" color="light" responsive="pc" size="150"></Logo>
                    </div>
                    <div v-show="this.darkTheme == 1" >
                        <Logo type="unCryptos" app="unCryptos" color="dark" responsive="pc" size="150"></Logo>
                    </div>
                </div>
                <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                <!-- <div>
                    <a href="https://demo.untrading.org/" type="button" class="button_root button_variant-secondary button_size-default button_variant link_root" rel="noopener">
                        <span class="text-tertiary" style="padding: 0px;">Demo</span><v-icon class="text-black01">mdi mdi-chevron-right</v-icon>
                    </a>
                </div> -->
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-secondary">
                        No Fees, No Predictions
                    </h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="text-tertiary fs-17">
                        Grow your profits through participation with Future Rewards.
                    </p>
                </div>
                <div class="Spacer_root__uoSvA" style="--height:32px"></div>
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-secondary">
                        Full Control
                    </h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="text-tertiary fs-17 gradient-underline-hover text-balance">
                        Keep your crypto in your wallet while earning risk-free "<a href="https://5173.foundation/" target="_blank" class="pointer text-tertiary del-underline link-underline-hover"><span class="gradient-text-hover">variable annuity</span></a>" rewards through Flows.
                    </p>
                </div>
                <div class="Spacer_root__uoSvA" style="--height:64px"></div>
                <div>
                    <Market></Market>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import Logo from '@/components/common/Logo';
    import Market from './Market.vue'; 
    import { mapGetters } from "vuex";
    export default {
        data(){
            return {
    
            }
        },
        components: { Logo, Market },
        created(){
    
        },
        mounted(){
    
        },
        computed: {
            ...mapGetters(['darkTheme']),
        },
        watch:{
    
        },
        methods: {
            
        }
    }
</script>
<style scoped></style>