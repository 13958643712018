<template>
    <!--/$--><!--$-->
    <div class="@container w-full">
        <div class="@lg:max-w-container @lg:grid-cols-4 gap-sm grid w-full grid-cols-1">
            <div class="@lg:col-span-3 @lg:sticky @lg:mb-0 mb-sm duration-medium grid-cols-1 self-start transition-[top] @lg:top-header-h">
                <div class="px-sm block md:hidden">
                    <div class="video-container">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/ZhfqFUJzE_w?si=2UiTvglQC620GZFU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="px-sm @md:px-md @lg:px-0 relative hidden md:block">
                    <div class="video-container">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/ZhfqFUJzE_w?si=2UiTvglQC620GZFU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    
                </div>
            </div>
            <div class="px-sm @md:px-md @lg:px-0 @lg:grid-cols-1 gap-y-xl gap-x-2xs col-span-1 grid grid-cols-1 @md:grid-cols-3">
                <div class="relative">
                    <router-link to="/shared-rewards"  class="transition ease-curve-a duration-250 group relative">
                        <div class="bg-primary-12 absolute left-0 top-0 w-full rounded-md" style="aspect-ratio:1/1"></div>
                        <div class="ease-curve-c duration-normal max-w-container-desktop relative mx-auto overflow-hidden transition-opacity rounded-md [&amp;_img]:scale-100 [&amp;_img]:transform-gpu [&amp;_video]:transform-gpu [&amp;_img]:transition-transform [&amp;_img]:ease-[curve(0.44, 0, 0.58, 1)] [&amp;_img]:duration-300 [&amp;_video]:transition-transform [&amp;_video]:ease-[curve(0.44, 0, 0.58, 1)] [&amp;_video]:duration-200 group-hover:[&amp;_img]:scale-[1.025] group-hover:[&amp;_video]:scale-[1.025]">
                            <div class="relative w-full" style="aspect-ratio:1/1">
                                <div class="relative h-full w-full overflow-hidden rounded-none aspect-1/1 ease-curve-d duration-normal bg-transparent transition-[background]">
                                    <v-img cover src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/df6f057e-7eaa-4474-29a3-6315dc23ea00/public"></v-img>
                                   </div>
                            </div>
                        </div>
                        <div class="pt-2xs duration-normal ease-curve-d w-full transition-[opacity,background]">
                            <div class="text-black01 relative w-full">
                                <div class="mb-2xs text_h5 md:text_h4 @md:pr-md">Shared Rewards</div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="relative">
                    <router-link to="/community-empowerment"  class="transition ease-curve-a duration-250 group relative">
                        <div class="bg-primary-12 absolute left-0 top-0 w-full rounded-md" style="aspect-ratio:1/1"></div>
                        <div class="ease-curve-c duration-normal max-w-container-desktop relative mx-auto overflow-hidden transition-opacity rounded-md [&amp;_img]:scale-100 [&amp;_img]:transform-gpu [&amp;_video]:transform-gpu [&amp;_img]:transition-transform [&amp;_img]:ease-[curve(0.44, 0, 0.58, 1)] [&amp;_img]:duration-300 [&amp;_video]:transition-transform [&amp;_video]:ease-[curve(0.44, 0, 0.58, 1)] [&amp;_video]:duration-200 group-hover:[&amp;_img]:scale-[1.025] group-hover:[&amp;_video]:scale-[1.025]">
                            <div class="relative w-full" style="aspect-ratio:1/1">
                                <div class="relative h-full w-full overflow-hidden rounded-none aspect-1/1 ease-curve-d duration-normal bg-transparent transition-[background]">
                                    <v-img cover src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/229748c8-950f-4e73-2c13-99bc99fcb100/public"></v-img>
                                </div>
                            </div>
                        </div>
                    </router-link>
                    <div class="pt-2xs duration-normal ease-curve-d w-full transition-[opacity,background]">
                        <div class="text-black01 relative w-full">
                            <div class="mb-2xs text_h5 md:text_h4 @md:pr-md">Community Empowerment</div>
                        </div>
                    </div>
                </div>
                <div class="relative">
                    <router-link to="/no-commission"  class="transition ease-curve-a duration-250 group relative">
                        <div class="bg-primary-12 absolute left-0 top-0 w-full rounded-md" style="aspect-ratio:1/1"></div>
                        <div class="ease-curve-c duration-normal max-w-container-desktop relative mx-auto overflow-hidden transition-opacity rounded-md [&amp;_img]:scale-100 [&amp;_img]:transform-gpu [&amp;_video]:transform-gpu [&amp;_img]:transition-transform [&amp;_img]:ease-[curve(0.44, 0, 0.58, 1)] [&amp;_img]:duration-300 [&amp;_video]:transition-transform [&amp;_video]:ease-[curve(0.44, 0, 0.58, 1)] [&amp;_video]:duration-200 group-hover:[&amp;_img]:scale-[1.025] group-hover:[&amp;_video]:scale-[1.025]">
                            <div class="relative w-full" style="aspect-ratio:1/1">
                                <div class="relative h-full w-full overflow-hidden rounded-none aspect-1/1 ease-curve-d duration-normal bg-transparent transition-[background]">
                                    <v-img cover src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/e71b6ba5-b88b-4f8f-712b-2c490e7ec700/public"></v-img>
                                </div>
                            </div>
                        </div>
                    </router-link>
                    <div class="pt-2xs duration-normal ease-curve-d w-full transition-[opacity,background]">
                        <div class="text-black01 relative w-full">
                            <div class="mb-2xs text_h5 md:text_h4 @md:pr-md">No Commission</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	/* 3:2 Aspect Ratio */
	height: 0;
	overflow: hidden;
	max-width: 100%;
}

.video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>