<template>
	<div class="markdown prose">
        <p>True vision isn't just about holding—it's about evolving.</p>
        <p >You see the big picture. But what if you could do more than just HODL?</p>
        <p >Untrading transforms diamond hands into perpetual profit:</p>
		<ul class="ml-8 custom-ul">
            <li>Wrap your assets</li>
            <li>Sell while staying connected</li>
            <li>Earn Future Rewards</li>
            <li>Rebuy and maximize gains</li>
        </ul>
        <p >Keep your long-term vision while unlocking new value streams.</p>
        <p >Because real diamond hands never leave the game.</p>
	</div>
</template>