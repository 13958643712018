<template>
	<div>
        <v-container class="mx-0-auto r-p-x-0 bg-black">
            <v-row no-gutters justify="center" align="center" class="py-100 bg-black">
                <Hero></Hero>
            </v-row>
            <v-row no-gutters class="bg-black" justify="center" align="center">
                <Redefining></Redefining>
             </v-row>
            <!-- CryptoScreenshot -->
            <v-row no-gutters class="bg-black pt-100" justify="center" align="center">
                <v-col cols="12" sm="12" md="12" xl="12" xxl="12" class="px-0">
                    <CryptoScreenshot></CryptoScreenshot>
                </v-col>
            </v-row>
            <v-row no-gutters class="bg-black" justify="center" align="center">
                <v-col cols="12" sm="12" md="12" xl="12" xxl="12" class="px-0">
                    <NftScreenshot></NftScreenshot>
                </v-col>
            </v-row>
            <v-row no-gutters class="py-100 bg-black" justify="center" align="center">
                <div class="layoutContent">
                    <v-col cols="12" sm="12" md="12" class="px-0">
                        <h2 class="typography_h1 text-quaternary">FAQs</h2>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="px-0 mt-8">
                        <Faq></Faq>
                        <div class="mt-16">
                            <a href="https://docs.untrading.org/faqs" target="_blank" type="button" class="px-4 button_root button_variant-secondary button_size-small button_variant link_root" rel="noopener">
                                <span class="text-tertiary" style="padding: 0px;">More</span>
                                <v-icon class="text-black01">mdi mdi-chevron-right</v-icon>
                            </a>
                        </div>
                    </v-col>
                </div>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import CryptoScreenshot from './v2/components/CryptoScreenshot.vue';
import Faq from './v2/components/Faq.vue';
import Footer from './v2/components/Footer.vue';
import Hero from './v2/components/Hero.vue';
import NftScreenshot from './v2/components/NftScreenshot.vue';
import Redefining from './v2/components/Redefining.vue';
export default {
    data(){
        return {

        }
    },
    components: { Hero, Redefining, CryptoScreenshot, NftScreenshot, Faq, Footer },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme",'responsive'])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>