<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">You’re a long-term visionary, investing for the future.</h3>
        <h3 class="text-black01 font-weight-bold">The Upside:</h3>
        <ul class="ml-8 custom-ul">
            <li>Less stress, less market watching.</li>
            <li>Potential for substantial long-term gains.</li>
            <li>Aligns with economic growth trends.</li>
            <li>Full control over your assets.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">The Downside:</h3>
        <ul class="ml-8 custom-ul">
            <li>Requires patience; capital is less liquid.</li>
            <li>Market downturns test your resolve.</li>
            <li>No immediate returns like dividends.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">The Hidden Risk:</h3>
        <p >Long-term holding isn’t just patience—it’s surviving:</p>
        <ul class="ml-8 custom-ul">
            <li>Emotional wear during bear markets.</li>
            <li>Temptation to sell at the wrong time.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">Untrading Innovates:</h3>
        <p >Welcome to Untrading—where selling is just the beginning.</p>
        <ol class="ml-8 custom-ol">
            <li><span class="text-black01 font-weight-bold">Wrap Your Assets: </span>Convert BTC (or others) into unBTC</li>
            <li><span class="text-black01 font-weight-bold">Sell & Stay Connected: </span>Earn Future Rewards (FRs) post-sale.</li>
            <li><span class="text-black01 font-weight-bold">Rebuy & Repeat: </span>Stay in control while unlocking rewards.</li>
        </ol>
        <p >With Untrading, you never truly exit the game.</p>
	</div>
</template>