<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">You Trade with Precision—But the Market Plays Dirty</h3>
        <p >You know how to time entries. You balance risk and reward. But even the best strategies fall to:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Sudden trend reversals.</span></li>
            <li><span class="text-black01 font-weight-bold">Institutional liquidity grabs.</span></li>
            <li><span class="text-black01 font-weight-bold">Unpredictable news events.</span></li>
        </ul>
        <p >At Untrading, we level the playing field.</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Keep earning after you sell with Future Rewards (FRs).</span></li>
            <li><span class="text-black01 font-weight-bold">Zero commissions, zero manipulation.</span></li>
            <li><span class="text-black01 font-weight-bold">Trade with confidence—without market makers working against you.</span></li>
        </ul>
        <p >Because smart traders deserve <span class="text-black01 font-weight-bold">more than one way to win.</span></p>
	</div>
</template>