<template>
	<div class="lexend-deca">
        <v-expansion-panels class="mb-6">
            <v-expansion-panel elevation="0" rounded="0">
                <v-expansion-panel-title collapse-icon="mdi:mdi-minus" class="text-secondary r-p-x-0 px-0 lexend-deca" expand-icon="mdi:mdi-plus"><h4 class="fs-21">What is untrading?</h4></v-expansion-panel-title>
                <v-expansion-panel-text>
                    <div class="text-tertiary prose">
                        <p>Untrading turns lone traders into thriving communities by enabling continuous rewards from asset appreciation, even after selling. Instead of facing the market alone, where over 90% of traders eventually lose everything, participants join asset-based communities called "Flows" where they benefit from ongoing Future Rewards.</p>
                        <p>Here's how it works:</p>
                        <p>When you buy a token on Untrading, you join its Flow - a community of past and present token holders. Through our innovative ERC-5173 standard, you'll continue receiving rewards from the token's future price increases even after you sell. This means your connection to an asset's success doesn't end with your sale.</p>
                        <p>For example:</p>
                        <ul class="pl-8">
                            <li>Buy a token and join its Flow</li>
                            <li> Sell when you reach your profit target</li>
                            <li>Keep earning rewards when future holders profit</li>
                            <li>Benefit from the community's long-term success</li>
                        </ul>
                        <p>Our platform transforms traditional trading in several key ways:</p>
                        <ul class="pl-8">
                            <li>No fees until profit is realized</li>
                            <li> Clear profit targets through our PE (Profit Expectancy) system</li>
                            <li>Community-driven growth through Kingdom building</li>
                            <li>Continuous rewards through Future Rewards</li>
                        </ul>
                        <p>As your involvement grows, you can progress from a community member to a Kingdom leader, helping shape the future of decentralized finance. Whether you're an experienced trader or just starting out, Untrading offers a more collaborative, sustainable way to participate in digital asset markets.</p>
                        <p>Remember: On Untrading, selling isn't goodbye - it's just the beginning of your earning potential.</p>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel elevation="0" rounded="0">
                <v-expansion-panel-title collapse-icon="mdi:mdi-minus" class="text-secondary r-p-x-0 px-0 lexend-deca" expand-icon="mdi:mdi-plus"><h4 class="fs-21">What are kingdoms, how do they work?</h4></v-expansion-panel-title>
                <v-expansion-panel-text class="fs-17 text-tertiary lexend-deca">
                    <div class="text-tertiary prose">
                        <p>Kingdoms are our gamified approach to community building and collaborative investing. Here's how they work:</p>
                        <ul class="pl-8">
                            <li>Start as a Knight: Begin your journey by creating or joining a small community.</li>
                            <li>Grow Your Influence: As your community grows and engages more with the platform, you climb the ranks.</li>
                            <li>Set Rules: As a leader, you can set custom rules for asset management and profit-sharing within your kingdom.</li>
                            <li>Collaborative Wealth: Work together with your community to maximize returns and share in the prosperity.</li>
                            <li>Rise to Royalty: Progress from Knight to Sheikh, Sultan, Jiangjun, and ultimately to King or Queen as your kingdom expands.</li>
                            <li>Outside Tournament: Join Untrading's annual tournaments and compete in exotic places around the world for prizes with other kingdoms.</li>
                        </ul>
                        <p>Kingdoms incentivize community growth, active participation, and collaborative strategies, aligning with Untrading's core principle of cooperative wealth creation.</p>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel elevation="0" rounded="0">
                <v-expansion-panel-title collapse-icon="mdi:mdi-minus" class="text-secondary r-p-x-0 px-0 lexend-deca" expand-icon="mdi:mdi-plus"><h4 class="fs-21">Why would I want to share my profit?</h4></v-expansion-panel-title>
                <v-expansion-panel-text class="fs-17 text-tertiary lexend-deca">
                    <div class="text-tertiary prose">
                        <p>Sharing profits on Untrading isn't about giving away your gains - it's about unlocking new avenues for wealth creation:</p>
                        <ul class="pl-8">
                            <li>Ongoing Rewards: By sharing a small portion of your profits, you gain the right to receive future rewards from assets you've sold, allowing you to defer a portion of your current earnings in exchange for the potential to receive larger future payouts, similar to buying a variable annuity. </li>
                            <li>Larger Profit Potential: The collective growth of assets benefits all participants, potentially leading to larger overall gains.</li>
                            <li>Reduced Risk: Diversify your earnings across multiple assets, even ones you no longer own.</li>
                            <li>Community Benefits: Stronger communities lead to more stable markets and better trading opportunities.</li>
                            <li>Aligned Incentives: When everyone benefits from an asset's success, it encourages better market behavior and reduces manipulation.</li>
                            <li>Transparency: The nFR framework provides more transparency around the asset's provenance and the distribution of future rewards, as it is built on the blockchain.</li>
                        </ul>
                        <p>Remember, on Untrading, you're not just sharing profits - you're gaining access to a new paradigm of collaborative wealth creation.</p>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
export default {
    data(){
        return {
           
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
ul {
	padding-left: 16px;
}


</style>