<template>
<div>
    <v-row no-gutters justify="center" align="center">
        <v-card color="black" class="px-10 py-10 layoutCardMax mx-auto" :class="darkTheme == 0 ? '' : 'card-outlined'" rounded="0" elevation="12">
            <v-col cols="12" sm="12" md="12">
               <div class="ga-2 d-flex flex-column">
                    <h1 class="fs-31 font-weight-bold text-white03">Untrading Extra Earnings Calculator</h1>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="text-primaryGrey fs-17">A core function of ERC-5173</p>
               </div>
               <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" xxl="12">
                        <div class="cal-16 text-primaryGrey font-weight-bold mb-4">Bought</div>
                        <v-row no-gutters justify="start" align="end">
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4" xxl="4">
                                <v-text-field v-model="nFRCalculatorRequest.size" type="number" min="0" step="0.000001" density="comfortable" rounded="0" variant="outlined" reverse hide-details class="montserrat" @blur="calculateNfr"></v-text-field>
                                <div class="cal-16 text-primaryGrey mt-2">Size</div>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4" xxl="4" class="px-2 r-p-x-0 r-m-t-4">
                                <v-select v-model="nFRCalculatorRequest.symbol" :items="unCryptos" item-title="symbol" item-value="symbol" density="comfortable" rounded="0" variant="outlined" hide-details class="montserrat">
                                    <template v-slot:item="{ props, item }">
                                        <v-list-item v-bind="props">
                                            <template v-slot:title>
                                                <span class="mr-2">{{ item.raw.symbol }}</span>
                                            </template>
                                        </v-list-item>
                                    </template>
                                </v-select>
                                <div class="cal-16 text-primaryGrey mt-2">Asset</div>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4" xxl="4" class="r-m-t-4">
                                <v-text-field v-model="nFRCalculatorRequest.buyPrice" type="number" min="0" step="0.01" density="comfortable" rounded="0" variant="outlined" reverse hide-details class="montserrat" @blur="calculateNfr"></v-text-field>
                                <div class="cal-16 text-primaryGrey mt-2">Price</div>
                            </v-col>
                        </v-row>
                        <div class="cal-16 text-primaryGrey font-weight-bold mb-4 mt-8">Sold</div>
                        <v-row no-gutters justify="start">
                            <v-col cols="12" sm="12" md="8" lg="8" xl="8" xxl="8" class="pr-2 r-p-x-0">
                                <v-select v-model="samePriceRatio" :items="profitExpectancies" item-value="priceChangeRatio" density="comfortable" rounded="0" variant="outlined" bg-color="light-green" reverse hide-details clearable class="montserrat" @update:modelValue="changeSamePriceRatio">
                                    <template v-slot:selection="{ item }">
                                        <span>{{ `${item.raw.title} (+${item.raw.priceChangeRatio}%)`}}</span>
                                    </template>
                                    <template v-slot:item="{ props, item }">
                                        <v-list-item v-bind="props">
                                            <template v-slot:title>
                                                <span>{{ `${props.key + 1} - ${item.raw.title} (+${item.raw.priceChangeRatio}%)`}}</span>
                                            </template>
                                        </v-list-item>
                                    </template>
                                </v-select>
                                <div class="cal-16 text-primaryGrey mt-2">Profit Expectancy (PE)</div>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4" xxl="4" class="r-m-t-4">
                                <v-text-field v-model="owners[0].price" type="number" min="0" step="0.01" density="comfortable" rounded="0" variant="outlined" :bg-color="priceBgColor(0)" reverse hide-details class="montserrat" @blur="calculateNfr"></v-text-field>
                                <div class="cal-16 text-primaryGrey mt-2">Price Sold</div>
                            </v-col>
                        </v-row>
                        <div class="fs-16 text-primaryGrey font-weight-bold mb-4 mt-8">Non-fungible Future Rewards (nFR) Parameters</div>
                        <v-row no-gutters justify="start" align="start">
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4" xxl="4">
                                <v-text-field v-model="nFRCalculatorRequest.totalRewardRatio" type="number" min="5" max="50" step="0.01" density="comfortable" placeholder="5% to 50%" rounded="0" variant="outlined" reverse hide-details class="montserrat" @blur="calculateNfr">
                                    <template v-slot:append-inner>%</template>
                                </v-text-field>
                                <div class="cal-16 text-primaryGrey mt-2">Total Reward Ratio (R)</div>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" lg="4" xl="4" xxl="4" class="px-2 r-p-x-0 r-m-t-4">
                                <v-text-field v-model="nFRCalculatorRequest.futureRewardRatio" type="number" min="88" max="95" step="0.01" density="comfortable" placeholder="88% to 95%" rounded="0" variant="outlined" reverse hide-details class="montserrat" @blur="calculateNfr">
                                    <template v-slot:append-inner>%</template>
                                </v-text-field>
                                <div class="cal-16 text-primaryGrey mt-2">Future Rewards Ratio (FR)</div>
                            </v-col>
                            <v-col sm="12" md="4" lg="4" xl="4" xxl="4" class="r-m-t-4">
                                <v-text-field v-model="nFRCalculatorRequest.numberOfGenerations" type="number" min="5" max="50" step="1" density="comfortable" placeholder="5 to 50" rounded="0" variant="outlined" reverse hide-details class="montserrat" @blur="changeGeneration"></v-text-field>
                                <div class="cal-16 text-primaryGrey mt-2"># of Generations</div>
                            </v-col>
                        </v-row>
                        <div class="cal-16 montserrat text-primaryGrey font-italic mt-10">Adjusting any of the parameters will result in different extra earnings.</div>
                    </v-col>
                </v-row>
                <div style="background:var(--bento-border);margin:64px 0" role="none" aria-orientation="horizontal" data-orientation="horizontal" class="Separator_root__dpDqU"></div>
                <v-row no-gutters justify="center" align="end">
                    <v-col cols="12" sm="12" md="12" xl="12" xxl="12">
                        <div class="font-weight-bold home-26 text-white03">My Total Untrading Profit</div>
                        <div class="home-64 mt-5 montserrat text-soft-lime-green">
                            <span>{{ $tools.formatNumber(nFRCalculatorResponse.totalProfit, 2) }}</span>
                            <span class="home-24 text-primaryGrey ml-5">{{ nFRCalculatorResponse.paymentToken }}</span>
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters justify="center" align="center" class="mt-5 r-m-t-8">
                    <v-col cols="12" sm="6" md="6" xl="6" xxl="6">
                        <div class="d-flex flex-wrap align-end home-36 text-olive-green montserrat">
                            <span>{{ $tools.formatNumber(nFRCalculatorResponse.holdingProfit, 2) }}</span>
                            <span class="home-16 text-primaryGrey ml-5 pb-2">{{ nFRCalculatorResponse.paymentToken }}</span>
                        </div>
                        <div class="montserrat text-primaryGrey font-16">P/L - trading on other platforms</div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" xl="6" xxl="6" class="r-m-t-16">
                        <div class="del-underline r-text-right">
                            <a  href="https://5173.foundation" target="_blank" type="button" class="button_root button_variant-ghost button_size-x-default button_variant link_root" rel="noopener">
                                <span class="gjcrSl lexend-deca" style="padding: 0px;">Advanced calculator</span><v-icon>mdi mdi-chevron-right</v-icon>
                            </a>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-row>
</div>
</template>

<script>
import CryptoNFRAPI from '@/api/crypto-nfr.js';
import Faq from './Faq.vue';
import CalculatorRequestExamples from '../data/calculator-request-examples.json';
export default {
    data() {
        return {
            // NFR 计算器请求参数
            nFRCalculatorRequest: {
                buyPrice: null,
                prices: [],
                size: null,
                symbol: 'unBTC/USDC',
                totalRewardRatio: null,
                futureRewardRatio: null,
                numberOfGenerations: null
            },
            // 拥有者集合
            owners: [],
            // 加密货币列表
            unCryptos: [{
                    currency: 'unBTC',
                    symbol: 'unBTC/USDC',
                    name: 'untrading Wrapped Bitcoin'
                },
                {
                    currency: 'unETH',
                    symbol: 'unETH/USDC',
                    name: 'untrading Wrapped Ether'
                },
                {
                    currency: 'unLINK',
                    symbol: 'unLINK/USDC',
                    name: 'untrading Wrapped Chainlink'
                },
                {
                    currency: 'unSOL',
                    symbol: 'unSOL/USDC',
                    name: 'untrading Wrapped Solana'
                },
                {
                    currency: 'UN²',
                    symbol: 'UN²/USDC',
                    name: 'untrading Wrapped UN'
                },
            ],
            // 利润预期列表
            profitExpectancies: [{
                    category: 'Small',
                    title: 'Hummingbird',
                    feature: 'constant, small gains',
                    priceChangeRatio: 5
                },
                {
                    category: 'Small',
                    title: 'Shrew',
                    feature: 'frequent, small meals',
                    priceChangeRatio: 8
                },
                {
                    category: 'Small',
                    title: 'Squirrel',
                    feature: 'regular foraging, storing for later',
                    priceChangeRatio: 10
                },
                {
                    category: 'Medium',
                    title: 'Rabbit',
                    feature: 'steady grazing, moderate gains',
                    priceChangeRatio: 12
                },
                {
                    category: 'Medium',
                    title: 'Deer',
                    feature: 'browsing, larger meals but more time between',
                    priceChangeRatio: 15
                },
                {
                    category: 'Medium',
                    title: 'Fox',
                    feature: 'opportunistic, varied diet',
                    priceChangeRatio: 18
                },
                {
                    category: 'Large',
                    title: 'Wolf',
                    feature: 'larger, less frequent meals, pack hunting',
                    priceChangeRatio: 20
                },
                {
                    category: 'Large',
                    title: 'Lion',
                    feature: 'big kills, longer between meals',
                    priceChangeRatio: 25
                },
                {
                    category: 'Large',
                    title: 'Elephant',
                    feature: 'large, steady consumption, long-term strategy',
                    priceChangeRatio: 30
                },
                {
                    category: 'Jumbo',
                    title: 'Crocodile',
                    feature: 'large, infrequent meals, patient hunter',
                    priceChangeRatio: 40
                },
                {
                    category: 'Jumbo',
                    title: 'Python',
                    feature: 'very large, very infrequent meals',
                    priceChangeRatio: 50
                },
                {
                    category: 'Jumbo',
                    title: 'Whale',
                    feature: 'filter feeding, constant but for huge amounts',
                    priceChangeRatio: 68
                }

            ],
            // 相同的价格比例
            samePriceRatio: null,
            // 聚焦的价格索引
            focusedPriceIndex: -1,
            // NFR 计算器响应参数
            nFRCalculatorResponse: {},
            // 加载中
            calculatorLoading: false,
        }
    },
    components: {
        Faq
    },
    created() {

    },
    mounted() {

    },
    computed: {

    },
    watch: {
        'nFRCalculatorRequest.symbol': {
            handler(n, o) {
                // ES6 的写法
                let examples = CalculatorRequestExamples.filter(item => item.symbol == n);
                if (examples.length > 0) {
                    // 复制一个对象
                    let request = JSON.parse(JSON.stringify(examples[0]));
                    this.nFRCalculatorRequest = request;
                    this.owners = request.owners;
                    // 根据参数计算 NFR
                    this.calculateNfr();
                }
            },
            immediate: true
        }
    },
    methods: {
        // 改变相同价格比例
        changeSamePriceRatio() {
            // 第一个价格
            let basePrice = this.nFRCalculatorRequest.buyPrice;
            // 价格小数位数
            let priceDecimals = CalculatorRequestExamples.filter(item => item.symbol == this.nFRCalculatorRequest.symbol)[0].priceDecimals;
            // 循环设置价格
            for (let index = 0; index < this.owners.length; index++) {
                let price = Number((Number(basePrice) * (1 + (this.samePriceRatio / 100))).toFixed(priceDecimals));
                this.owners[index].price = price;
                basePrice = price;
            }
            // 根据参数计算 NFR
            this.calculateNfr();
        },
        // 价格背景色
        priceBgColor(index) {
            if (index == 0) {
                if (this.owners[index].price == null || this.owners[index].price == '' || Number(this.owners[index].price) == Number(this.nFRCalculatorRequest.buyPrice)) {
                    return 'primaryGrey';
                } else if (Number(this.owners[index].price) > Number(this.nFRCalculatorRequest.buyPrice)) {
                    return 'dark-green';
                } else {
                    return 'dark-red';
                }
            }
            if (this.owners[index].price == null || this.owners[index].price == '' || Number(this.owners[index].price) == Number(this.owners[index - 1].price)) {
                return 'primaryGrey';
            }
            return Number(this.owners[index].price) > Number(this.owners[index - 1].price) ? 'dark-green' : 'dark-red';
        },
        // 数字背景色
        numberBgColor(num) {
            if (num == null || num == '' || Number(num) == 0) {
                return 'primaryGrey';
            }
            return Number(num) > 0 ? 'dark-green' : 'dark-red';
        },
        // 改变价格聚焦索引
        changeFocusedPriceIndex(focused, index) {
            this.focusedPriceIndex = focused ? index : this.focusedPriceIndex;
        },
        // 改变代数
        changeGeneration() {
            // 清除统一价格比例
            this.samePriceRatio = null;
            // 过滤当前交易对
            let examples = CalculatorRequestExamples.filter(item => item.symbol == this.nFRCalculatorRequest.symbol);
            if (examples.length > 0) {
                // 复制一个对象
                let owners = JSON.parse(JSON.stringify(examples[0].owners));
                this.owners = owners.splice(0, this.nFRCalculatorRequest.numberOfGenerations);
                // 计算差值
                let diff = this.nFRCalculatorRequest.numberOfGenerations - this.owners.length;
                // 循环差值
                for (let index = 0; index < diff; index++) {
                    // 上一个价格
                    const lastPrice = Number(this.owners[this.owners.length - 1].price);
                    // 获取下一个个位数  
                    const nextDigit = (this.owners.length + 1) % 10;
                    let ratio = 1.05;
                    // 判断个位数是否为3或8，则价格降低
                    if (nextDigit === 3 || nextDigit === 8) {
                        ratio = 0.95
                    }
                    // 计算下一个价格
                    const price = Number(Number(lastPrice * ratio).toFixed(examples[0].priceDecimals));
                    const owner = {
                        ownerNo: this.owners.length + 1,
                        price: price,
                        profit: null,
                        rewards: [],
                        total: null
                    };
                    this.owners.push(owner);
                }
                // 根据参数计算 NFR
                this.calculateNfr();
            }
        },
        // 改变价格
        changePrice(ratio, index) {
            if (ratio != 0) {
                let basePrice = 0;
                if (index == 0) {
                    basePrice = this.nFRCalculatorRequest.buyPrice;
                } else {
                    basePrice = this.owners[index - 1].price;
                }
                // 价格小数位数
                let priceDecimals = CalculatorRequestExamples.filter(item => item.symbol == this.nFRCalculatorRequest.symbol)[0].priceDecimals;
                let price = Number((Number(basePrice) * (1 + ratio)).toFixed(priceDecimals));
                this.owners[index].price = price;
            }
            // 关闭价格快捷键
            this.focusedPriceIndex = -1;
            // 根据参数计算 NFR
            this.calculateNfr();
        },
        // 检查参数
        async checkParams() {
            // 检查数量
            if (Number(this.nFRCalculatorRequest.size) <= 0) {
                this.$store.dispatch('snackbarMessageHandler', "Invalid size");
                return false;
            }
            // 检查购买价格
            if (Number(this.nFRCalculatorRequest.buyPrice) < 0) {
                this.$store.dispatch('snackbarMessageHandler', "Invalid buy price");
                return false;
            }
            // 检查总奖励比率
            if (Number(this.nFRCalculatorRequest.totalRewardRatio) < 5 || Number(this.nFRCalculatorRequest.totalRewardRatio) > 50) {
                this.$store.dispatch('snackbarMessageHandler', "Invalid total reward ratio");
                return false;
            }
            // 检查未来奖励比率
            if (Number(this.nFRCalculatorRequest.futureRewardRatio) < 88 || Number(this.nFRCalculatorRequest.futureRewardRatio) > 95) {
                this.$store.dispatch('snackbarMessageHandler', "Invalid future reward ratio");
                return false;
            }
            // 检查代数
            if (Number(this.nFRCalculatorRequest.numberOfGenerations) < 5 || Number(this.nFRCalculatorRequest.numberOfGenerations) > 50) {
                this.$store.dispatch('snackbarMessageHandler', "Invalid generations");
                return false;
            }
            // 检查价格
            if (this.owners.some(item => Number(item.price) < 0)) {
                this.$store.dispatch('snackbarMessageHandler', "Invalid price");
                return false;
            }
            return true;
        },
        // 根据参数计算 NFR
        async calculateNfr() {
            // 检查参数
            if (!await this.checkParams()) {
                return;
            }
            // 请求加载中
            this.calculatorLoading = true;
            // 从拥有者集合提取价格数组
            let prices = this.owners.map(item => item.price);
            this.nFRCalculatorRequest.prices = prices;
            // 发送请求
            let res = await CryptoNFRAPI.calculateNfr(this.nFRCalculatorRequest);
            let data = res.data;
            if (data.success) {
                this.nFRCalculatorResponse = data.data;
                this.owners = data.data.owners;
            }
            // 请求结束
            this.calculatorLoading = false;
        }
    }
}
</script>

<style scoped>

</style>
