<template>
	<div class="markdown prose">
        <p>Living for the trade? You know the rush—and the risks.</p>
        <p>Quick gains come with steep falls. Market manipulation, stop hunts, and volatility can erase profits instantly.</p>
        <p>Enter Untrading: Where selling isn't goodbye.</p>
        <ul>
            <li>Keep earning after you exit</li>
            <li>Zero hidden fees</li>
            <li>Future Rewards on past trades</li>
        </ul>
        <p>Don't just trade. Untrade—and let your success compound even after you move on.</p>
	</div>
</template>