<template>
	<div class="markdown prose">
        <p class="text-black01 font-weight-bold">You trade hard. You win big. But when you sell, the profits stop.</p>
        <p>With Untrading, they don’t.</p>
        <ul class="custom-ul">
            <li><span class="text-black01 font-weight-bold">Sell, but stay in the game.</span></li>
            <li><span class="text-black01 font-weight-bold">Zero commissions, total fairness.</span></li>
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs) keep you earning.</span></li>
        </ul>
        <p>Trade smarter. Keep winning. <span class="text-black01 font-weight-bold">Untrade.</span></p>
	</div>
</template>