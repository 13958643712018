<template>
    <div>
        <v-navigation-drawer :model-value="drawer" fixed width="380" location="left" color="f9f9f9-171717" border="none">
            <v-list-item class="mt-3">
                <router-link to="/" class="pointer">
                    <div v-show="this.darkTheme == 1" >
                        <Logo type="full_untrading" app="untrading" color="dark" size="94"></Logo>
                        
                    </div>
                    <div v-show="this.darkTheme == 0" >
                        <Logo type="full_untrading" app="untrading" color="light" size="94"></Logo>
                    </div>
                </router-link>
            </v-list-item>
            <v-list class="menu-14 poppins mt-100 px-0">
                <a @click="startOver()" class="pointer del-underline text-primaryGrey font-weight-bold">
                    <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">My past trading experience</a>
                    </v-list-item>
                </a>
                <a @click="scrollToSection('ai-inspiration')" class="pointer del-underline text-primaryGrey font-weight-bold">
                    <v-list-item class="gradient-underline-hover">
                        <a class="text-primaryGrey gradient-text-hover">My financial future</a>
                    </v-list-item>
                </a>
            </v-list>
            <template v-slot:append>
                <v-list-item class="mb-8">
                    <v-list-item-title>
                        <Theme color="primaryGrey" size="20"></Theme>
                    </v-list-item-title>
                </v-list-item>
            </template>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import Logo from '@/components/common/Logo';
    import Installation from '@/components/common/Installation';
    import Theme from '@/components/common/Theme';
    import { mapGetters } from "vuex";
    export default {
        props: {
            drawer: {
                type: Boolean,
                default: false
            },
        },
        data(){
            return {
                // 是否窄栏
                rail: false,
            }
        },
        components: { Logo, Installation, Theme },
        created(){
    
        },
        mounted(){
    
        },
        computed: {
            ...mapGetters(['darkTheme', 'responsive']),
        },
        watch:{
    
        },
        methods: {
            // 滚动到单元
            scrollToSection(sectionId) {
                setTimeout(() => {
                    const section = document.getElementById(sectionId);
                    // 滚动到指定位置，留出 64px 的 header 间距
                    if (section) {
                        section.style.scrollMarginTop = "64px"; // 设置滚动时的顶部间距
                        section.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 100);
            },
            // 监听导航栏显示状态
            watchNavigationDrawer(show) {
                this.$emit('watchNavigationDrawer', show);
            },
            // 重新开始
            startOver() {
                this.$store.dispatch("tradingExperienceHandler", null);
                this.$store.dispatch("inspirationHandler", null);
                localStorage.removeItem('untrading-welcome');
                window.location.href = '/';
            }
        }
    }
</script>
<style scoped>
    /* 条目激活时显示主题色 */
    /* .v-list-item--active {
    background: rgb(var(--v-theme-primary)) !important;
    color: rgb(var(--v-theme-primary)) !important;
    } */
</style>