<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">You’re a strategic trader who values patience and precision.</h3>
        <h3 class="text-black01 font-weight-bold">The Upside:</h3>
        <ul class="ml-8 custom-ul">
            <li>Blend of technical and fundamental analysis for informed decisions.</li>
            <li>Balanced risk-reward ratio.</li>
            <li>Less time-intensive than day trading but still profitable.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">The Downside:</h3>
        <ul class="ml-8 custom-ul">
            <li>Requires emotional discipline and resilience.</li>
            <li>Markets can reverse unexpectedly.</li>
            <li>Still susceptible to manipulative practices.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">The Hard Truth:</h3>
        <p >Even the most disciplined traders face psychological traps like overconfidence, FOMO, and gambler’s fallacy, leading to reversals of fortune.</p>
        <ul class="ml-8 custom-ul">
            <li>Market capitulations erase even the best-planned positions.</li>
            <li>Unexpected news events or macro shifts can reverse trends in seconds.</li>
            <li>Institutions exploit liquidity zones—just when your confidence is highest.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">The Untrading Solution:</h3>
        <p >Welcome to Untrading—getting paid after selling your tokens.</p>
        <ol class="ml-8 custom-ol">
            <li><span class="text-black01 font-weight-bold">No Fees. No Middlemen. No Predictions: </span>Keep more of what you earn, without the guesswork.</li>
            <li><span class="text-black01 font-weight-bold">Earn Future Rewards (FRs): </span>Continue to benefit as assets appreciate even after you sell.</li>
            <li><span class="text-black01 font-weight-bold">Trade Smarter—Without Getting Played: </span>Stay ahead of market manipulation and unfair practices.</li>
        </ol>
        <p >With Untrading, you trade on your terms.</p>
	</div>
</template>