<template>
	<div>
        <v-row no-gutters>
            <v-col cols="12" md="12">
                <div class="text-h5 md:text-h4 mb-2xs @md:pr-2xl">Untrading vs. Others: A Unique Approach</div>
                <p class="text-p2">Untrading differentiates itself by emphasizing fairness, innovation, and community success, merging non-custodial security, commission-free trades, and shared rewards via ERC-5173.</p>
            </v-col>
        </v-row>
        <div class="table-container mt-5">
            <table class="w-100 table-min-width text_caption">
                <thead class="text-black01">
                    <tr>
                        <th class="text-left  px-xs pl-0" style="width: 150px;">Feature</th>
                        <th class="text-left px-xs" style="width: 150px;">Untrading</th>
                        <th class="text-left px-xs" style="width: 150px;">CEX (Centralized Exchange)</th>
                        <th class="text-left px-xs" style="width: 150px;">DEX (Decentralized Exchange)</th>
                        <th class="text-left px-xs" style="width: 150px;">DeFi Projects (General)</th>
                        <th class="text-left px-xs" style="width: 150px;">Crypto ETFs</th>
                        <th class="text-left px-xs pr-0" style="width: 150px;">Mutual Funds</th>
                    </tr>
                </thead>
                <tbody class="text-primaryGrey">
                    <tr v-for="(feature, i) in features" :key="`features-${i}`" class="pb-2">
                        <td class="py-2.5 pl-0 text-black01">{{ feature.feature }}</td>
                        <td class="px-xs pl-0 py-2.5" :class="feature.description.untrading.style">{{ feature.description.untrading.description }}</td>
                        <td class="px-xs pl-0 py-2.5" :class="feature.description.cex.style">{{ feature.description.cex.description }}</td>
                        <td class="px-xs pl-0 py-2.5" :class="feature.description.dex.style">{{ feature.description.dex.description }}</td>
                        <td class="px-xs pl-0 py-2.5" :class="feature.description.deFiProjects.style">{{ feature.description.deFiProjects.description }}</td>
                        <td class="px-xs pl-0 py-2.5" :class="feature.description.cryptoETFs.style">{{ feature.description.cryptoETFs.description }}</td>
                        <td class="py-2.5 pr-0" :class="feature.description.mutualFunds.style">{{ feature.description.mutualFunds.description }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-h5 md:text-h4 mb-2xs @md:pr-2xl mt-6">Key Highlights of Untrading</div>
        <div class="prose-1">
            <ul>
                <li>
                    <router-link to="/shared-rewards" aria-label="Shared Rewards" class="del-underline text-primaryGery font-weight-bold gradient-underline-hover">
                        <a class="text-primaryGery link-underline-hover gradient-text-hover">Shared Rewards</a>
                    </router-link>
                    <span class="text-black01 opacity-60">: ERC-5173 enables past owners to gain from future price rises, promoting a cooperative environment.</span>
                </li>
                <li>
                    <router-link to="/community-empowerment" aria-label="Community Empowerment" class="del-underline text-primaryGery font-weight-bold gradient-underline-hover">
                        <a class="text-primaryGery link-underline-hover gradient-text-hover">Community Empowerment</a>
                    </router-link>
                    <span class="text-black01 opacity-60">: "Flows" and "Kingdoms" encourage collective investment and shared success.</span>
                </li>
                <li>
                    <a @click="scrollToSection('transparency')" aria-label="Transparency & Control">
                        <router-link to="/community-empowerment" aria-label="Transparency & Control" class="del-underline text-primaryGery font-weight-bold gradient-underline-hover">
                            <a class="text-primaryGery link-underline-hover gradient-text-hover">Transparency & Control</a>
                        </router-link>
                    </a>
                    <span class="text-black01 opacity-60">: Complete on-chain visibility and user asset sovereignty.</span>
                </li>
                <li>
                    <router-link to="/no-commission" aria-label="No Commissions" class="del-underline text-primaryGery font-weight-bold gradient-underline-hover">
                        <a class="text-primaryGery link-underline-hover gradient-text-hover">No Commissions</a>
                    </router-link>
                    <span class="text-black01 opacity-60">: Shares only in profits, no trading fees.</span>
                </li>
                <li>
                    <router-link to="/no-commission" aria-label="Secure Autonomy" class="del-underline text-primaryGery font-weight-bold gradient-underline-hover">
                        <a class="text-primaryGery link-underline-hover gradient-text-hover">Secure Autonomy</a>
                    </router-link>
                    <span class="text-black01 opacity-60">: Assets managed through smart contracts, no custodial risk.</span>
                </li>
                <li>
                    <a @click="scrollToSection('pe')" aria-label="Profit Expectancy">
                        <router-link to="/no-commission" aria-label="Profit Expectancy" class="del-underline text-primaryGery font-weight-bold gradient-underline-hover">
                            <a class="text-primaryGery link-underline-hover gradient-text-hover">Profit Expectancy</a>
                        </router-link>
                    </a>
                    <span class="text-black01 opacity-60">: Beyond entry, includes position management, exit, and risk control for a complete system.</span>
                </li>
                <li>
                    <router-link to="/innovation" aria-label="Innovation & Compliance" class="del-underline text-primaryGery font-weight-bold gradient-underline-hover">
                        <a class="text-primaryGery link-underline-hover gradient-text-hover">Innovation & Compliance</a>
                    </router-link>
                    <span class="text-black01 opacity-60">: Blends DeFi's innovation with compliance for security and trust.</span>
                </li>
            </ul>
        </div>
	</div>
</template>
<script>
import router from '@/router';

export default {
    data(){
        return {
            features: [
                {   
                    feature: 'Custody', 
                    description: {
                        untrading: { description: 'Non-custodial, managed by smart contracts', style: ['font-weight-bold', 'text-black01'] },
                        cex: { description: 'Custodial (exchange holds assets)', style: [] },
                        dex: { description: 'Non-custodial (user holds assets)', style: ['font-weight-bold', 'text-black01'] },
                        deFiProjects: { description: 'Non-custodial, smart contracts', style: ['font-weight-bold', 'text-black01'] },
                        cryptoETFs: { description: 'Custodial (managed by ETF provider)', style: [] },
                        mutualFunds: { description: 'Custodial (managed by institution)', style: [] },
                    }
                },
                {   
                    feature: 'Security', 
                    description: {
                        untrading: { description: 'Blockchain-based, smart contract security', style: ['font-weight-bold', 'text-black01'] },
                        cex: { description: 'Prone to hacks, insider risks', style: [] },
                        dex: { description: 'Smart contract-based security', style: ['font-weight-bold', 'text-black01'] },
                        deFiProjects: { description: 'Varies by project; some vulnerable to exploits', style: [] },
                        cryptoETFs: { description: 'Regulated but centralized', style: [] },
                        mutualFunds: { description: 'Regulated, centralized risk', style: [] },
                    }
                },
                {   
                    feature: 'Risk Management', 
                    description: {
                        untrading: { description: 'Innovative systems like reputation scores and wash trade prevention', style: ['font-weight-bold', 'text-black01'] },
                        cex: { description: 'Exchange-controlled; risk of mismanagement', style: [] },
                        dex: { description: 'Relies on smart contract audits & liquidity depth', style: [] },
                        deFiProjects: { description: 'Project-dependent; mixed reliability', style: [] },
                        cryptoETFs: { description: 'Managed by financial institutions', style: [] },
                        mutualFunds: { description: 'Managed by fund managers', style: [] },
                    }
                },
                {   
                    feature: 'Fees', 
                    description: {
                        untrading: { description: 'No commissions, only share in realized gains', style: ['font-weight-bold', 'text-black01'] },
                        cex: { description: 'Trading fees, withdrawal fees, spreads', style: [] },
                        dex: { description: 'High slippage, liquidity provider fees, gas fees', style: [] },
                        deFiProjects: { description: 'Varied fees, some fee-sharing', style: [] },
                        cryptoETFs: { description: 'Management fees, trading fees', style: [] },
                        mutualFunds: { description: 'Management fees, transaction fees', style: [] },
                    }
                },
                {   
                    feature: 'Liquidity', 
                    description: {
                        untrading: { description: 'Community-driven, improving with adoption', style: [] },
                        cex: { description: 'High; large user base & institutional backing', style: ['font-weight-bold', 'text-black01'] },
                        dex: { description: 'Moderate to high; depends on liquidity pools', style: [] },
                        deFiProjects: { description: 'Varies widely; depends on platform adoption', style: [] },
                        cryptoETFs: { description: 'High; backed by traditional markets', style: ['font-weight-bold', 'text-black01'] },
                        mutualFunds: { description: 'High; backed by traditional markets', style: ['font-weight-bold', 'text-black01'] },
                    }
                },
                {   
                    feature: 'Profit Sharing', 
                    description: {
                        untrading: { description: 'Future Rewards (FR) for past and present holders', style: ['font-weight-bold', 'text-black01'] },
                        cex: { description: 'No profit-sharing; traders compete in a zero-sum system', style: [] },
                        dex: { description: 'Limited; yield farming & LP rewards', style: [] },
                        deFiProjects: { description: 'Yield rewards, staking incentives', style: [] },
                        cryptoETFs: { description: 'No direct profit-sharing', style: [] },
                        mutualFunds: { description: 'No direct profit-sharing', style: [] },
                    }
                },
                {   
                    feature: 'Accessibility', 
                    description: {
                        untrading: { description: 'Open to all, no minimums, minimal KYC', style: ['font-weight-bold', 'text-black01'] },
                        cex: { description: 'Restricted access, requires KYC', style: [] },
                        dex: { description: 'Anyone with a crypto wallet', style: ['font-weight-bold', 'text-black01'] },
                        deFiProjects: { description: 'Varies; some projects require staking or governance', style: [] },
                        cryptoETFs: { description: 'Available via traditional finance channels', style: [] },
                        mutualFunds: { description: 'Requires brokerage access; often high minimums', style: [] },
                    }
                },
                {   
                    feature: 'Governance', 
                    description: {
                        untrading: { description: 'Community-led via UN token voting', style: ['font-weight-bold', 'text-black01'] },
                        cex: { description: 'Centralized; exchange controls decision-making', style: [] },
                        dex: { description: 'Some governance via DAOs', style: [] },
                        deFiProjects: { description: 'Typically DAO-driven, but varies widely', style: [] },
                        cryptoETFs: { description: 'Managed governance by institutions', style: [] },
                        mutualFunds: { description: 'Institution-led decision-making', style: [] },
                    }
                },
                {   
                    feature: 'Asset Types', 
                    description: {
                        untrading: { description: 'Crypto, NFTs, real-world asset tokens', style: ['font-weight-bold', 'text-black01'] },
                        cex: { description: 'Crypto assets', style: [] },
                        dex: { description: 'Crypto assets, some synthetic assets', style: [] },
                        deFiProjects: { description: 'Crypto, staking, synthetic assets', style: [] },
                        cryptoETFs: { description: 'Basket of crypto assets', style: [] },
                        mutualFunds: { description: 'Stocks, bonds, diversified assets', style: [] },
                    }
                },
                {   
                    feature: 'Transparency', 
                    description: {
                        untrading: { description: 'Complete on-chain transparency', style: ['font-weight-bold', 'text-black01'] },
                        cex: { description: 'Opaque, internal records controlled by exchange', style: [] },
                        dex: { description: 'On-chain transactions but risk of wash trading', style: [] },
                        deFiProjects: { description: 'On-chain but fragmented across projects', style: [] },
                        cryptoETFs: { description: 'Transparent pricing, but holdings are managed', style: [] },
                        mutualFunds: { description: 'Limited visibility into fund allocation', style: [] },
                    }
                },
                {   
                    feature: 'User Control', 
                    description: {
                        untrading: { description: 'Full self-custody, no third-party control', style: ['font-weight-bold', 'text-black01'] },
                        cex: { description: 'Limited; funds held by the exchange', style: [] },
                        dex: { description: 'Full control over assets and private keys', style: ['font-weight-bold', 'text-black01'] },
                        deFiProjects: { description: 'Varies; typically full control', style: [] },
                        cryptoETFs: { description: 'Limited control; managed by institution', style: [] },
                        mutualFunds: { description: 'Limited control; managed by institution', style: [] },
                    }
                },
                {   
                    feature: 'Community Incentives', 
                    description: {
                        untrading: { description: 'Flows and Kingdoms for collaborative growth', style: ['font-weight-bold', 'text-black01'] },
                        cex: { description: 'No community incentives', style: [] },
                        dex: { description: 'Liquidity provider & governance rewards', style: [] },
                        deFiProjects: { description: 'Staking incentives, DAO voting power', style: [] },
                        cryptoETFs: { description: 'No direct community engagement', style: [] },
                        mutualFunds: { description: 'No community engagement', style: [] },
                    }
                },
                {   
                    feature: 'Regulation', 
                    description: {
                        untrading: { description: 'DeFi-compliant, adheres to on-chain governance models', style: ['font-weight-bold', 'text-black01'] },
                        cex: { description: 'Highly regulated, but exchanges can manipulate markets', style: [] },
                        dex: { description: 'Loosely regulated; depends on jurisdiction', style: [] },
                        deFiProjects: { description: 'Minimal regulation; enforcement varies by project', style: [] },
                        cryptoETFs: { description: 'Highly regulated under traditional finance laws', style: ['font-weight-bold', 'text-black01'] },
                        mutualFunds: { description: 'Strictly regulated by financial authorities', style: ['font-weight-bold', 'text-black01'] },
                    }
                },
                {   
                    feature: 'Innovation', 
                    description: {
                        untrading: { description: 'High; pioneered ERC-5173 for shared success', style: ['font-weight-bold', 'text-black01'] },
                        cex: { description: 'Moderate; primarily focused on operational efficiency', style: [] },
                        dex: { description: 'High; continuous protocol upgrades', style: [] },
                        deFiProjects: { description: 'Varies; some projects drive DeFi evolution', style: [] },
                        cryptoETFs: { description: 'Low; follows traditional finance models', style: [] },
                        mutualFunds: { description: 'Low; traditional financial structures dominate', style: [] },
                    }
                }
            ]
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        // 滚动到单元
        scrollToSection(sectionId) {
            setTimeout(() => {
                const section = document.getElementById(sectionId);
                if (section) {
                    section.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        },
    }
}
</script>
<style scoped>
.table-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 20px;
}
.table-min-width {
    min-width: 1100px;
    max-width: 1500px;
}
table { 
	width: 100%; 
	border-collapse: collapse; 
}
tr th {
	padding: 2px 0px; 
	border-bottom: 2px solid rgb(var(--v-theme-black01)); 
	text-align: left; 
}

td { 
	border-bottom: 1px solid rgb(var(--v-theme-f3f3f3-303030)); 
	text-align: left; 
}

[data-toggle="toggle"] {
	display: none;
}
</style>