<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Diamond Hands? Make Them Work Harder</h3>
        <p >Long-term investing means discipline, patience, and conviction. But it also means:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">No immediate returns. </span>Unlike dividends or staking, holding generates no passive income.</li>
            <li><span class="text-black01 font-weight-bold">Market stagnation pressure. </span>Holding through downturns can test your resolve.</li>
            <li><span class="text-black01 font-weight-bold">Missed opportunities. </span>Others profit while you wait for long-term gains.</li>
        </ul>
        <p >Untrading gives you an edge:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Wrap your BTC (or other assets) into unBTC.</span></li>
            <li><span class="text-black01 font-weight-bold">Sell unBTC while staying connected through Future Rewards (FRs).</span></li>
            <li><span class="text-black01 font-weight-bold">Use proceeds to rebuy assets in the open market, amplifying returns.</span></li>
        </ul>
        <p >Maximize the long game without <span class="text-black01 font-weight-bold">losing your position.</span></p>
	</div>
</template>