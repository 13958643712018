<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">You plan your trades. You time your exits. But why should profits stop when you sell?</h3>
        <p >With <span class="text-black01 font-weight-bold">Untrading</span>, they don’t.</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Sell and stay in the game.</span></li>
            <li><span class="text-black01 font-weight-bold">Zero commissions, total transparency.</span></li>
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs) keep you earning.</span></li>
        </ul>
        <h3 class="text-black01 font-weight-bold">Untrading flips the script:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Your profits don’t stop at selling. </span> Earn Future Rewards (FRs) post-sale.</li>
            <li><span class="text-black01 font-weight-bold">No commissions, no middlemen. </span>Keep more of what you earn.</li>
            <li><span class="text-black01 font-weight-bold">Trade on your terms, without market makers exploiting you.</span></li>
        </ul>
        <p >Trade smarter. Stay ahead. <span class="text-black01 font-weight-bold">Untrade.</span></p>
	</div>
</template>