<template>
    <div>
        <!-- unCryptos 侧边导航栏 -->
        <un-cryptos-navigation-mobile v-if="$route.meta.app == 'unCryptos'" :drawer="navigationDrawer" @watchNavigationDrawer="watchNavigationDrawer"></un-cryptos-navigation-mobile>
        <!-- unNFTs 侧边导航栏 -->
        <un-nfts-navigation-mobile v-if="$route.meta.app == 'unNFTs'" :drawer="navigationDrawer" @watchNavigationDrawer="watchNavigationDrawer"></un-nfts-navigation-mobile>
        <v-app-bar fixed elevation="0" color="f2f">
            <template v-slot:prepend>
                <v-icon icon="mdi:mdi-menu" size="28" color="primaryGrey" @click="navigationDrawer = !navigationDrawer"></v-icon>
            </template>
            <div v-if="$route.meta.app" class="pointer mb-1">
                <!-- <div v-show="this.darkTheme == 0">
                    <Logo type="icon" :app="$route.meta.app" size="24"></Logo>
                </div>
                <div v-show="this.darkTheme == 1" >
                    <Logo type="icon" :app="$route.meta.app" size="24"></Logo>
                </div> -->
                <UntradingApp :app="$route.meta.app"></UntradingApp>
            </div>
            <template v-slot:append>
                <v-row no-gutters justify="space-between">
                    <Avatar class="mr-2"></Avatar>
                    <span class="mr-2" v-if="token == null">
                        <router-link :to="'/connectwallet?redirectUrl=' + currentUrl">
                            <v-menu open-on-hover location="top">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" color="primary" icon="mdi mdi-wallet-outline" class="pointer" size="28"></v-icon>
                                </template>
                                <v-card class="pa-3 text-primaryGrey" rounded="0">Connect Wallet</v-card>
                            </v-menu>
                        </router-link>
                    </span>
                </v-row>
            </template>
        </v-app-bar>
        <!-- 【不显示】固定 Discord -->
        <a v-if="false" href="https://discord.gg/D5rZdhzVWh" target="_blank" class="del-underline" aria-label="Discord">
            <v-btn position="fixed" location="bottom right" class="mr-6 mb-12" icon variant="text" size="large" style="z-index: 999;">
                <v-menu v-model="fixedDiscordMenu" transition="slide-y-transition" :offset="10" location="top" open-on-hover contained>
                    <template v-slot:activator="{ props }" >
                        <div v-bind="props" >
                            <v-icon class="icon" color="secondary" size="32">icon-dis</v-icon>
                        </div>
                    </template>
                    <v-card class="pa-3 text-primaryGrey" rounded="0">
                        <h6 class="body-p font-weight-thin text-none">We are online! How may I help you today? </h6>
                    </v-card>
                </v-menu>
            </v-btn>
        </a>
        <!--【不显示】固定举报作弊图标 -->
        <v-btn v-if="false" position="fixed" location="bottom right" class="mr-6" icon variant="text" size="large" style="z-index: 999;" @click="reportCheatingDialog = true">
            <v-menu v-model="fixedReportCheatingMenu" transition="slide-y-transition" :offset="10" location="top" open-on-hover contained>
                <template v-slot:activator="{ props }" >
                    <div v-bind="props" >
                        <v-icon class="icon" color="primaryGrey" size="32">icon-alert</v-icon>
                    </div>
                </template>
                <v-card class="pa-3 text-primaryGrey" rounded="0">
                    <h6 class="body-p font-weight-thin text-none">Report Cheating</h6>
                </v-card>
            </v-menu>
        </v-btn>
        <!-- 举报作弊弹窗 -->
        <ReportCheating :show="reportCheatingDialog" @watchReportCheatingDialog="watchReportCheatingDialog"></ReportCheating>
        <!-- 消息条 -->
        <Snackbar></Snackbar>
    </div>
</template>
<script>
    import Logo from '@/components/common/Logo';
    import Theme from '@/components/common/Theme';
    import Notifications from '@/components/common/Notifications';
    import Installation from '@/components/common/Installation';
    import Avatar from '@/components/user/Avatar';
    import ReportCheating from '@/components/common/ReportCheating';
    import Snackbar from '@/components/common/Snackbar';
    import Search from '@/components/common/Search';
    import UntradingApp from '@/components/common/UntradingAppV2';
    import UnCryptosNavigationMobile from '@/components/common/navigation/v2/UnCryptosNavigationMobile';
    import UnNftsNavigationMobile from '@/components/common/navigation/v2/UnNFTsNavigationMobile';
    import { mapGetters } from "vuex";
    import { mergeProps } from 'vue'
    export default {
        data(){
            return {
                // 显示搜索条
                showSearchBar: false,
                // 举报作弊弹窗
                reportCheatingDialog: false,
                currentUrl: window.location.href,
                // NFT 菜单
                nftMenus: [
                    { title: 'Explore', icon: 'mdi:mdi-compass', to: '/market' },
                    { title: 'Curated', icon: 'mdi:mdi-star', to: '/sets' },
                    { title: 'Collections', icon: 'mdi:mdi-image-filter-black-white', to: '/collections' }
                ],
                // 固定 Discord 菜单
                fixedDiscordMenu: false,
                // 固定举报作弊菜单
                fixedReportCheatingMenu: false,
                // 侧边导航栏菜单
                navigationDrawer: false,
                // 搜索图表路径
                searchIconPaths: ['/uncryptos'],
                // 搜索文本路径
                searchTextPaths: ['/uncryptos/search'],
                search: null
            }
        },
        components: { Logo, Notifications, Installation, Avatar, ReportCheating, Theme, Snackbar, Search, UntradingApp, UnCryptosNavigationMobile, UnNftsNavigationMobile },
        created(){
    
        },
        mounted(){
    
        },
        computed: {
            ...mapGetters(['darkTheme', 'token', 'cryptoWrapPermission', 'cryptoProducts']),
            productMenus() {
                let menus = [
                    { title: 'unCryptos', iconType: 'icon', icon: null, linkType: 'to', link: '/uncryptos' },
                    { title: 'unNFTs', iconType: 'icon', icon: null, linkType: 'to', link: '/unnfts' },
                    { title: 'Testnet', iconType: 'icon', icon: null, linkType: 'a', link: 'https://testnet.untrading.org' },
                ];
                return menus;
            },
            docMenus() {
                let menus = [
                    { title: 'ERC-5173', iconType: 'icon', icon: null, linkType: 'a', link: 'https://eips.ethereum.org/EIPS/eip-5173' },
                    { title: 'Whitepaper', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/whitepaper' },
                    { title: 'Tutorials', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/tutorials' },
                ];
                return menus;
            },
            // 关于菜单
            aboutMenus() {
                let menus = [
                    { title: 'About', iconType: 'icon', icon: null, linkType: 'to', link: '/about' },
                    { title: 'Network DeFi', iconType: 'icon', icon: null, linkType: 'to', link: '/network-defi' },
                    { title: 'FAQs', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/faqs' },
                    { title: 'Terms of Service', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/terms-of-service' },
                    { title: 'Privacy Policy', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/privacy-policy' },
                    { title: 'Legal', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org/risks' },
                    { title: '5173 Foundation', iconType: 'icon', icon: null, linkType: 'a', link: 'https://5173.foundation' },
                    { title: 'UN Token', iconType: 'icon', icon: null, linkType: 'to', link: '/un' },
                ];
                return menus;
            },
             // 开发者菜单
            developerMenus() {
                let menus = [
                    { title: 'Documentation', iconType: 'icon', icon: null, linkType: 'a', link: 'https://docs.untrading.org' },
                    { title: 'GitHub', iconType: 'icon', icon: null, linkType: 'a', link: 'https://github.com/untrading' },
                ];
                return menus;
            },
            // Crypto 菜单
            cryptoMenus() { 
                let menus = [
                    { title: 'Markets', iconType: 'icon', icon: 'mdi:mdi-trending-up', to: '/uncryptos' },
                ];
                this.cryptoProducts.forEach(product => {
                    let menu = {
                        title: product.currency,
                        iconType: 'image',
                        icon: product.icon,
                        to: `/uncryptos?product=${product.currency}`
                    }
                    menus.push(menu);
                });
                return menus;
            }
        },
        watch:{
            $route: {
                handler(n, o) {
                    this.currentUrl = window.location.href;
                },
                immediate: true
            },
        },
        methods: {
            // 监听举报作弊弹窗
            watchReportCheatingDialog(show) {
                this.reportCheatingDialog = show;
            },
            // 监听侧边栏
            watchNavigationDrawer(show) {
                this.navigationDrawer = show;
            },
            mergeProps
        }
    }
</script>
<style scoped></style>