<template>
	<div>
        <v-container class="pa-0 r-p-x-0">
            <v-row no-gutters justify="center" align="center" class="py-100 bg-black">
                <EarnRewards></EarnRewards>
            </v-row>
            <v-row no-gutters justify="center" align="center" class="py-100 bg-black">
                <Communities></Communities>
            </v-row>
            <v-row no-gutters justify="center" align="center" class="py-100 bg-black" id="transparency">
                <Beyond></Beyond>
            </v-row>
            <v-row no-gutters justify="center" align="center" class="py-100 bg-black">
                <GetStarted></GetStarted>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Beyond from './v2/components/Beyond.vue';
import Communities from './v2/components/Communities.vue';
import EarnRewards from './v2/components/EarnRewards.vue';
import GetStarted from './v2/components/GetStarted.vue';
export default {
    data(){
        return {

        }
    },
    components: { Beyond, Communities, GetStarted, EarnRewards},
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme",'responsive'])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>