<template>
	<div>
        <v-container class="mx-0-auto r-p-x-0 bg-black">
            <v-row no-gutters align="end" justify="center" class="hero_section pb-16">
                <div class="layoutContent">
                    <div class="Grid_root__iRfoa grid-style-17">
                        <div class="Grid_a__vY7M8">
                            <h2 class="typography_h1 text-quaternary">
                                Maximize Your Earnings with the Untrading Calculator
                            </h2>
                            <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                            <div class="fs-14 text-tertiary prose-1">
                                <p >The Untrading Extra Earnings Calculator helps you estimate how much additional profit you could earn using Untrading—on a single transaction or across multiple future sales.</p>
                                <p>How It Works:</p>
                                <ul class="pl-8">
                                    <li>Input Your Buy and Sell Prices: Start with your basic trade details.</li>
                                    <li>Adjust Profit Expectancy Levels: Customize the calculator to match your trading strategy.</li>
                                    <li>See Potential Earnings: Discover how much extra you could earn through Future Rewards (FRs).</li>
                                </ul>
                                <p>Go Deeper with the Advanced Calculator:</p>
                                <p class="gradient-underline-hover">For a more detailed analysis, use the <a href="https://5173.foundation/" target="_blank" class="del-underline link-underline-hover text-tertiary pointer gradient-text-hover"><span class="gradient-text-hover">Advanced Calculator</span></a> to explore in-depth scenarios and optimize your trading potential.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </v-row>
            <v-row no-gutters align="end" justify="center" class="hero_section pb-150">
                <div class="layoutContentCal">
                    <Calculator></Calculator>
                </div>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Calculator from './v2/components/Calculator.vue';
export default {
    data(){
        return {

        }
    },
    components: { Calculator},
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme",'responsive'])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>