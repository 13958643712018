<template>
	<div class="markdown prose">
        <p >Your inspiration: <span class="text-black01 font-weight-bold">Soros. “It’s not about being right—it’s about maximizing when you are.”</span></p>
        <p >Untrading is built for tactical traders like you. We eliminate friction, fees, and inefficiencies so you can focus on what matters:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">No Commissions: </span>Keep more of your profits.</li>
            <li><span class="text-black01 font-weight-bold">No Market Predictions: </span>Trade without guesswork.</li>
            <li><span class="text-black01 font-weight-bold">Pure Asymmetric Opportunities: </span>Maximize your wins when you’re right.</li>
        </ul>
        <p >With Untrading, you’re free to focus on strategy—not obstacles.</p>
    </div>
</template>