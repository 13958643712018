<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Wealth Isn’t Built on Luck—It’s Built on Strategy</h3>
        <p >Diversification. Balance. Risk-adjusted growth. You don’t chase wins—you build systems that create them.</p>
        <h3 class=" text-black01 font-weight-bold">Untrading aligns with this mindset:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs) keep you earning post-sale.</span></li>
            <li><span class="text-black01 font-weight-bold">Transparent, decentralized profit-sharing.</span></li>
            <li><span class="text-black01 font-weight-bold">A framework designed for stability, not speculation.</span></li>
        </ul>
        <p >Because wealth isn’t extracted—it’s <span class="text-black01 font-weight-bold">sustained.</span></p>
    </div>
</template>