<template>
	<div class="markdown prose">
        <p>The Art of Patient Prosperity</p>
        <p>True wealth isn't built overnight. It's cultivated through time, conviction, and wisdom.</p>
        <p>Untrading enhances your value strategy:</p>
        <ul class="ml-8 custom-ul">
            <li>Sell when prices are right—keep earning forever</li>
            <li>Maintain complete asset control</li>
            <li>Build multi-generational wealth streams</li>
        </ul>
        <p>Because real investing isn't about timing the market. It's about letting time work for you.</p>
        <p>Transform your patience into perpetual prosperity.</p>
    </div>
</template>