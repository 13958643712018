<template>
	<div class="markdown prose">
        <p>VALUE INVESTMENT OPTIMIZATION FRAMEWORK</p>
        <p >Core Principles:</p>
		<ul class="ml-8 custom-ul">
            <li>Patient Capital Deployment</li>
            <li>Perpetual Value Creation</li>
            <li>Generational Wealth Building</li>
            <li>Strategic Control Retention</li>
        </ul>
        <p>Implementation Strategy:</p>
		<ol class="ml-8 custom-ol">
            <li>
                <span>Position Establishment</span>
                <ul class="ml-8 custom-circle-ul">
                    <li>Value Assessment</li>
                    <li>Entry Optimization</li>
                    <li>Control Preservation</li>
                </ul>
            </li>
            <li>
                <span>Wealth Generation</span>
                <ul class="ml-8 custom-circle-ul">
                    <li>Future Rewards Accumulation</li>
                    <li>Passive Income Creation</li>
                    <li>Value Compounding</li>
                </ul>
            </li>
            <li>
                <span>Legacy Building</span>
                <ul class="ml-8 custom-circle-ul">
                    <li>Multi-generational Planning</li>
                    <li>Wealth Preservation</li>
                    <li>Strategic Growth</li>
                </ul>
            </li>
        </ol>
        <p >Transform traditional value investing into perpetual prosperity through systematic value creation.</p>
        <p >Because the best investment is the one that never stops paying.</p>
	</div>
</template>