<template>
    <div>
        <v-navigation-drawer :model-value="drawer" temporary width="380"  location="left"  color="black" class="h-100" border="none" style="top: 0;">
            <!-- <v-list-item class="mt-3">
                <router-link to="/" class="pointer">
                    <div v-show="this.darkTheme == 1" >
                        <Logo type="header" color="light" responsive="pc"></Logo>
                    </div>
                    <div v-show="this.darkTheme == 0" >
                        <Logo type="header" color="dark" responsive="pc"></Logo>
                    </div>
                </router-link>
            </v-list-item> -->
                <v-list class="menu-14 mt-100 px-0">
                    <router-link to="/shared-rewards" aria-label="Shared Rewards" class="del-underline text-primaryGrey font-weight-bold">
                        <v-list-item class="gradient-underline-hover">
                            <a class="text-primaryGrey gradient-text-hover">Shared Rewards</a>
                        </v-list-item>
                    </router-link>
                    <router-link to="/community-empowerment" aria-label="Community Empowerment" class="del-underline text-primaryGrey font-weight-bold">
                        <v-list-item class="gradient-underline-hover">
                            <a class="text-primaryGrey gradient-text-hover">Community Empowerment</a>
                        </v-list-item>
                    </router-link>
                    <a @click="scrollToSection('transparency')" aria-label="Transparency">
                        <router-link to="/community-empowerment" class="pointer del-underline text-primaryGrey font-weight-bold">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">Transparency</a>
                            </v-list-item>
                        </router-link>
                    </a>
                    <router-link to="/no-commission" aria-label="No Commission" class="del-underline text-primaryGrey font-weight-bold">
                        <v-list-item class="gradient-underline-hover">
                            <a class="text-primaryGrey gradient-text-hover">No Commission</a>
                        </v-list-item>
                    </router-link>
                    <a @click="scrollToSection('pe')" aria-label="Profit Expectancy">
                        <router-link to="/no-commission" class="pointer del-underline text-primaryGrey font-weight-bold">
                            <v-list-item class="gradient-underline-hover">
                                <a class="text-primaryGrey gradient-text-hover">Profit Expectancy</a>
                            </v-list-item>
                        </router-link>
                    </a>
                    <router-link to="/innovation" aria-label="innovation" class="del-underline text-primaryGrey font-weight-bold">
                        <v-list-item class="gradient-underline-hover">
                            <a class="text-primaryGrey gradient-text-hover">Innovation</a>
                        </v-list-item>
                    </router-link>
                    <router-link to="/future-rewards" aria-label="Future Rewards Calculator" class="del-underline text-primaryGrey font-weight-bold">
                        <v-list-item class="gradient-underline-hover">
                            <a class="text-primaryGrey gradient-text-hover">Future Rewards Calculator</a>
                        </v-list-item>
                    </router-link>
                    <router-link to="/un" aria-label="Claim UN Tokens" class="del-underline text-primaryGrey font-weight-bold">
                        <v-list-item class="gradient-underline-hover">
                            <a class="text-primaryGrey gradient-text-hover">Claim UN Tokens</a>
                        </v-list-item>
                    </router-link>
                    <a href="https://kingdoms.untrading.org" aria-label="Kingdoms" class="del-underline text-primaryGrey font-weight-bold"  target="_blank">
                        <v-list-item class="gradient-underline-hover">
                            <a class="text-primaryGrey gradient-text-hover">Kingdoms</a>
                        </v-list-item>
                    </a>
                    <router-link to="/kols" aria-label="KOLs" class="del-underline text-primaryGrey font-weight-bold">
                        <v-list-item class="gradient-underline-hover">
                            <a class="text-primaryGrey gradient-text-hover">KOLs</a>
                        </v-list-item>
                    </router-link>
                </v-list>
            <template v-slot:append>
                <v-list-item class="mb-8">
                    <v-list-item-title>
                        <Theme color="primaryGrey" size="20"></Theme>
                    </v-list-item-title>
                </v-list-item>
            </template>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import Logo from '@/components/common/Logo';
    import Installation from '@/components/common/Installation';
    import Theme from '@/components/common/Theme';
    import { mapGetters } from "vuex";
    export default {
        props: {
            drawer: {
                type: Boolean,
                default: false
            },
        },
        data(){
            return {
            }
        },
        components: { Logo, Installation, Theme },
        created(){
    
        },
        mounted(){
    
        },
        computed: {
            ...mapGetters(['darkTheme', 'responsive']),
        },
        watch:{
    
        },
        methods: {
            // 滚动到单元
            scrollToSection(sectionId) {
                const section = document.getElementById(sectionId);
                if (section) {
                    section.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }
</script>
<style scoped>
    /* 条目激活时显示主题色 */
    /* .v-list-item--active {
    background: rgb(var(--v-theme-primary)) !important;
    color: rgb(var(--v-theme-primary)) !important;
    } */
</style>