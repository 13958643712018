<template>
	<div class="align-center bg-lightGray">
        <v-container class="h-100">
           <v-card color="transparent" elevation="0" max-width="1400" class="mx-auto pt-8">
                 <v-row no-gutters justify="center" align="start">
                    <v-col cols="12" sm="6" lg="4" class="mt-16 r-m-t-0 hide-tablet">
                        <Step :steps="signInSteps" :action="action" class="ml-12 r-m-l-0"></Step>
                    </v-col>
                    <v-col cols="12" sm="6" lg="5" class="r-m-t-8">
                        <!-- 选择钱包 -->
                        <div v-show="action == 'CONNECT_WALLET'" class="pb-16">
                            <h1 class="sub-page-h1 mb-6 text-grey04">Sign up/sign in</h1>
                            <v-card class="pa-8" :loading="walletStatusLoading" rounded="0" max-width="510" elevation="12" color="white01">
                                <template v-slot:loader="{ isActive }">
                                    <v-progress-linear :active="isActive" indeterminate bg-opacity="1" bg-color="white01" color="primary"></v-progress-linear>
                                </template>
                                <v-row no-gutters>
                                    <v-col>
                                        <h2 class="sidebar-h2 text-primaryGrey">Welcome!</h2>
                                    </v-col>
                                    <v-divider class="my-4"></v-divider>
                                    <div>
                                        <div class="body-p mb-8 text-primaryGrey">Select a wallet to connect to untrading.org</div>
                                        <div v-if="responsive == 'pc'">
                                            <v-col cols="12" class="my-2" justify="center" align="center" v-for="(wallet, i) in supportedWallets" :key="i">
                                                <div class="d-flex align-center pointer" @click="selectWallet(wallet.type)">
                                                    <img :src="wallet.logo" height="40" />
                                                    <div class="ml-5 body-p-medium text-primaryGrey">
                                                        {{ wallet.name }}
                                                    </div>
                                                </div>
                                            </v-col>
                                        </div>
                                    </div>
                                    <div v-if="responsive == 'mobile'">
                                        <v-col cols="12" class="my-2" justify="center" align="center" v-for="(wallet, i) in supportedMobileWallets" :key="i">
                                            <div class="d-flex align-center pointer" @click="selectWallet(wallet.type)">
                                                <img :src="wallet.logo" height="40" />
                                                <div class="ml-5 body-p-medium text-primaryGrey">
                                                    {{ wallet.name }}
                                                </div>
                                            </div>
                                        </v-col>
                                    </div>
                                </v-row>
                            </v-card>
                        </div>
                        <!-- 欢迎 -->
                        <div v-show="action == 'WELCOME'" class="pb-16">
                            <div class="sub-page-h1 mb-6 text-primaryGrey">{{ currentStatus == 'SIGN_UP' ? 'Sign up' : 'Sign in' }}</div>
                            <v-card class="pa-8" :loading="walletStatusLoading" rounded="0" max-width="510" elevation="12" color="white01">
                                <template v-slot:loader="{ isActive }">
                                    <v-progress-linear :active="isActive" indeterminate bg-opacity="1" bg-color="white01" color="primary"></v-progress-linear>
                                </template>
                                <v-row no-gutters>
                                    <v-col>
                                        <h2 class="sidebar-h2 text-primaryGrey">{{ currentStatus == 'SIGN_UP' ? 'Welcome!' : 'Welcome back!' }} </h2>
                                        <v-divider class="my-4"></v-divider>
                                        <!-- 钱包地址 -->
                                        <div class="body-p mb-3 text-primaryGrey">Your wallet address is: </div>
                                        <div class="body-p mb-10">
                                            <v-text-field v-model="walletAddress" variant="solo" hide-details readonly density="comfortable"></v-text-field>
                                        </div>
                                        <!-- 推荐码 -->
                                        <div class="body-p mb-16" v-if="currentStatus == 'SIGN_UP'">
                                            <v-text-field v-model="referralCodeUsed" label="Referral code (Optional)" variant="outlined" hide-details density="comfortable"></v-text-field>
                                            <div class="body-p mt-3 text-primaryGrey">
                                                <span>If you have a referral code, enter it here to unlock special promotional benefits! Enjoy exclusive rewards and bonuses by taking advantage of our referral program.</span>
                                            </div>
                                        </div>
                                        <div class="body-p mb-10 text-primaryGrey">
                                            <span v-if="currentStatus == 'SIGN_UP'">Sign the message in your wallet to sign up.</span>
                                            <span v-else>Sign the message in your wallet to log in.</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <div class="mt-16">
                                <a class="gradient-left-red-purple-300 del-underline">
                                    <v-btn rounded="0" elevation="2" width="300" height="66" color="button01" class="text-none text-grey05 body-p-small-b" @click="action = 'SIGN_MESSAGE'" aria-label="Sign message in wallet">Sign message in wallet</v-btn>
                                </a>
                            </div>
                        </div>
                        <!-- 签名信息 -->
                        <div v-show="action == 'SIGN_MESSAGE'" class="pb-16">
                            <div class="sub-page-h1 mb-6 text-primaryGrey">{{ currentStatus == 'SIGN_UP' ? 'Sign up' : 'Sign in' }}</div>
                            <v-card class="pa-8" rounded="0" max-width="510" elevation="12" color="white01">
                                <v-row no-gutters>
                                    <v-col>
                                        <h2 class="sidebar-h2 text-primaryGrey">Sign the message in your wallet</h2>
                                        <v-divider class="my-4"></v-divider>
                                        <div class="body-p mb-3 text-primaryGrey">untrading.org uses this signature to verify that you are the owner of this wallet address.</div>
                                        <div class="body-p mb-16 text-primaryGrey">By connecting your wallet and using untrading.org, you agree to out 
                                            <span class='del-underline gradient-underline-hover'>
                                                <a href="https://docs.untrading.org/terms-of-service" class="text-primaryGrey link-underline-hover" target="_blank">
                                                    <span class='gradient-text-hover'>Terms of Service</span>
                                                </a>
                                            </span>
                                            and 
                                            <span class='del-underline gradient-underline-hover'>
                                                <a href="https://docs.untrading.org/privacy-policy" class="text-primaryGrey link-underline-hover" target="_blank">
                                                    <span class='gradient-text-hover'>Privacy Policy</span>
                                                </a>
                                            </span>
                                            .</div>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <div class="mt-16">
                                <!-- Cloudflare Turnstile 人机验证 -->
                                <v-card rounded="0" elevation="2" width="300" height="65">
                                    <div class="cf-turnstile" data-sitekey="0x4AAAAAAASLFw40x6_7D1nI" :data-callback="onloadTurnstileCallback()" :data-theme="darkTheme == 1 ? 'dark' : 'light'" :data-language="turnstileDataLanguage" data-response-field-name="cf-turnstile-connectwallet-response"></div>
                                </v-card>
                            </div>
                            <div class="mt-5">
                                <!-- 调用钱包签名按钮 -->
                                <a class="gradient-left-red-purple-300 del-underline">
                                    <v-btn rounded="0" elevation="2" :loading="signLoading" width="300" height="66" color="button01" class="text-none text-grey05 body-p-small-b" @click="signMessageRequest" aria-label="Sign message in wallet">Sign message in wallet</v-btn>
                                </a>
                            </div>
                        </div>
                        <!-- 注册完成 -->
                        <div v-show="action == 'COMPLETION'" class="pb-16">
                            <h1 class="sub-page-h1 mb-6 text-grey04">Sign up</h1>
                            <v-card class="pa-8" rounded="0" max-width="510" elevation="12" color="white01">
                                <v-row no-gutters>
                                    <v-col>
                                        <h2 class="sidebar-h2 text-primaryGrey">Sign up complete!</h2>
                                        <v-divider class="my-4"></v-divider>
                                        <div class="body-p mb-3 text-primaryGrey">Congratulations! 🎉</div>
                                        <div class="body-p mb-3 text-primaryGrey">Your sign-in and account verification are complete. You can now take full advantage of the Untrading platform for upgraded select NFTs and ERC-20 tokens. Explore profit capturing after selling your tokens, build long-term financial wealth without the need for market predictions, and join a community that's transforming the financial landscape.</div>
                                        <div class="body-p mb-16 text-primaryGrey">Welcome to Untrading!</div>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <div class="mt-16">
                                <a class="gradient-left-red-purple-300 del-underline">
                                    <v-btn rounded="0" elevation="2" width="300" height="66" color="button01" class="text-none text-grey05 body-p-small-b" @click="connectWallet" aria-label="OK">OK</v-btn>
                                </a>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="6" lg="4" class="mt-16 r-m-t-0 show-tablet">
                        <Step :steps="signInSteps" :action="action" class="ml-12 r-m-l-0"></Step>
                    </v-col>
                </v-row>
           </v-card>
        </v-container>
        <!-- 选择钱包弹窗 -->
        <v-dialog v-model="selectWalletDialog" content-class="v-echarts-dialog">
            <v-card max-width="462" class="pa-8" rounded="0" elevation="12" color="white01">
                <v-row no-gutters>
                    <v-col>
                        <h2 class="sidebar-h2 text-primaryGrey">Pick a wallet to connect to untrading.org</h2>
                    </v-col>
                    <v-divider class="my-4"></v-divider>
                    <v-col cols="12" class="my-2" justify="center" align="center" v-for="(wallet, i) in supportedWallets" :key="i">
                        <div class="d-flex align-center pointer" @click="selectWallet(wallet.type)">
                            <img :src="wallet.logo" height="40" />
                            <div class="ml-5 body-p-medium text-primaryGrey">
                                {{ wallet.name }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @emitGetRegisterStatus="getRegisterStatus" @signSuccess="signSuccess" @signError="signError"></MetaMask>
        <Reown ref="Reown" @emitGetRegisterStatus="getRegisterStatus" @signSuccess="signSuccess" @signError="signError"></Reown>
	</div>
</template>
<script>
import UserAPI from '@/api/user';
import PreRegisterUserAPI from '@/api/pre-register-user';
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            // 操作
            action: 'CONNECT_WALLET',
            // 当前是注册还是登录？
            currentStatus: 'SIGN_UP',
            // 登录步骤
            signInSteps: [
                { title: 'Connect your wallet', codes: ['CONNECT_WALLET', 'WELCOME'] },
                { title: 'Sign the message in your wallet', codes: ['SIGN_MESSAGE', 'COMPLETION'] }
            ],
            // 支持的钱包
            supportedWallets: [
                { name: 'MetaMask', type: 'MetaMask', logo: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/c8b5a76c-1284-44db-9523-b74f96ae4e00/public' },
                { name: 'WalletConnect', type: 'Reown', logo: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/3967d7ba-a338-4d9b-aa5f-7b7873601600/public' },
            ],
            // 支持的钱包
            supportedMobileWallets: [
                { name: 'WalletConnect', type: 'Reown', logo: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/3967d7ba-a338-4d9b-aa5f-7b7873601600/public' },
            ],
            // 选择钱包弹窗
            selectWalletDialog: false,
            // 已经注册的用户对象
            user: {
                username: null
            },
            // 填写的推荐码
            referralCodeUsed: this.$route.query.referralCodeUsed,
            // 钱包状态加载中
            walletStatusLoading: false,
            // 签名加载中
            signLoading: false,
            // 签名消息
            signMessage: 'untrading.org uses this signature to verify that you are the owner of this wallet address.',
            // 签名字符串
            signature: null,
            // 重定向网址
            redirectUrl: this.$route.query.redirectUrl,
            // turnstile 小组件主键
            turnstileConnectWalletWidgetId: null,
            // turnstile 响应
            turnstileConnectWalletResponse: null
        }
    },
    components: { },
    created(){
        
    },
    mounted(){
        // Client-side rendering - https://developers.cloudflare.com/turnstile/get-started/client-side-rendering
        // 渲染 Turnstile 小组件，并返回小组件主键
        this.turnstileConnectWalletWidgetId = turnstile.render(".cf-turnstile");
        // Reset a widget - https://developers.cloudflare.com/turnstile/get-started/client-side-rendering#reset-a-widget
        // 重置 Turnstile 小组件
        turnstile.reset(this.turnstileConnectWalletWidgetId);
    },
    computed: {
        ...mapGetters(['walletAddress', 'walletType', 'token', 'responsive', 'locale', 'darkTheme']),
        // 语言
        turnstileDataLanguage() {
            return this.locale.toLowerCase();
        }
    },
    watch:{
        walletAddress: {
            handler(n, o) {
                if(n) {
                    // 查看钱包地址的注册状态
                    this.getRegisterStatus();
                }
            }
        },
    },
    methods: {
        // 选择钱包
        selectWallet(walletType) {
            this.selectWalletDialog = false;
            this.$store.dispatch('walletTypeHandler', walletType);
            if(walletType) {
                this.$refs[walletType].connect();
            } else {
                this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
            }
        },
        // 查看钱包地址的注册状态
        async getRegisterStatus() {
            this.walletStatusLoading = true;
            let res = await UserAPI.status(this.walletAddress);
            // let res = await UserAPI.status('0xaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
            let data = res.data;
            if (data.success) {
                this.action = 'WELCOME';
                this.$forceUpdate();
                switch(data.data) {
                    case 'REGISTERED': 
                        this.getSimpleUserByWallet();
                        this.currentStatus = 'SIGN_IN';
                        break;
                    default:
                        this.currentStatus = 'SIGN_UP';
                        break;
                }
            }
            this.walletStatusLoading = false;
        },
        // 根据钱包地址获取简单用户信息
        async getSimpleUserByWallet() {
            let res = await UserAPI.getSimpleUserByWallet(this.walletAddress);
            let data = res.data;
            if(data.success) {
                this.user = data.data;
            }
        },
        // 签名请求
        signMessageRequest() {
            // 方式一：使用 js 获取 Turnstile 的响应
            // // 使用 querySelector 查找具有特定 name 属性的输入字段
            // const inputElement = document.querySelector('input[name="cf-turnstile-connectwallet-response"]');
            // // 确保元素存在 && value 不为空
            // if (inputElement && inputElement.value) {
            //     this.turnstileConnectWalletResponse = inputElement.value;
            // }
            // Access a widget’s state - https://developers.cloudflare.com/turnstile/get-started/client-side-rendering/#access-a-widgets-state
            // 方式二：使用官方 API 获取 Turnstile 的响应
            this.turnstileConnectWalletResponse = turnstile.getResponse(this.turnstileConnectWalletWidgetId);
            if (!this.turnstileConnectWalletResponse) {
                this.$store.dispatch('snackbarMessageHandler', "Please verify that you are human.");
                return;
            }
            this.signLoading = true;
            if(this.walletType){
                this.$refs[this.walletType].sign(this.signMessage);
                // 60s 超时
                setTimeout(() => {
                    this.$store.dispatch('snackbarMessageHandler', "Request timedout");
                    this.signLoading = false;
                }, 60000);
            } else {
                this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                this.signLoading = false;
            }
        },
        // 签名成功
        signSuccess(signature){
            this.signLoading = false;
            // 签名成功
            this.signature = signature;
            if(this.currentStatus == 'SIGN_UP') {
                // 注册
                this.signup();
            } else {
                // 登录调用API链接钱包
                this.connectWallet();
            }
        },
        // 签名错误
        signError(signature){
            this.signLoading = false;
        },
        // 注册 
        async signup() {
            this.signLoading = true;
            let param = {
                // wallet: '0xaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
                wallet: this.walletAddress,
                referralCodeUsed: this.referralCodeUsed,
                cloudflareTurnstileToken: this.turnstileConnectWalletResponse
            };
            let res = await UserAPI.signup(param);
            let data = res.data;
            this.signLoading = false;
            if (data.code == 200) {
                // 注册成功
                this.action = 'COMPLETION';
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        },
        // 链接钱包进行登录
        async connectWallet() {
            this.signLoading = true;
            let param = {
                wallet: this.walletAddress,
                signature: this.signature,
                message: this.signMessage,
                walletType: this.walletType,
                cloudflareTurnstileToken: this.turnstileConnectWalletResponse
            };
            let res = await UserAPI.connectWallet(param);
            let data = res.data;
            this.signLoading = false;
            if (data.code == 200) {
                // 存入 token
                this.$store.dispatch("tokenHandler", data.data);
                // 查询用户数据
                this.$bus.emit('emitGetMe', {});
                // 打开欢迎弹窗
                localStorage.setItem('untrading-welcome', 1);
                // 重定向
                this.redirect();
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);;
            }
        },
        // 重定向
        redirect() {
            if(this.redirectUrl) {
                window.open(this.redirectUrl, "_self");
            } else {
                this.$router.push("/");
            }
        },
        // Turnstile 小组件加载成功
        onloadTurnstileCallback() {
            console.log('Turnstile Widget loaded successfully!');
        },
    }
}
</script>
<style scoped>
.align-center {
    position: relative;
    margin: 0 auto;
    height: 100%;
}
/* :deep .v-echarts-dialog {
    width: auto;
} */
/* ::v-deep(.v-echarts-dialog) {
     width: auto;
} */
:deep(.v-echarts-dialog){
    width: auto;
}
</style>