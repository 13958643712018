<template>
	<div class="markdown prose">
        <p>Your inspiration: Dalio. “Diversification, balance, and risk-adjusted returns create lasting wealth.”</p>
        <p >Untrading aligns with your mindset:</p>
        <ul class="ml-8 custom-ul">
            <li>Built-in Future Rewards (FRs).</li>
            <li>Decentralized, transparent profit-sharing.</li>
            <li>A framework for stability and growth.</li>
        </ul>
        <p >Build collaborative ecosystems where success is shared—not extracted.</p>
    </div>
</template>