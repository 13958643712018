<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">True Wealth is Built on Principles, Not Luck</h3>
        <p >You don’t just trade—you construct a <span class="text-black01 font-weight-bold">balanced, resilient portfolio.</span>You see past short-term noise and focus on <span class="text-black01 font-weight-bold">risk-adjusted, sustainable returns.</span></p>
        <p >But traditional trading platforms don’t support this vision:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">They extract rather than distribute.</span></li>
            <li><span class="text-black01 font-weight-bold">They prioritize speculation over strategy.</span></li>
            <li><span class="text-black01 font-weight-bold">They limit your upside to single transactions.</span></li>
        </ul>
        <h3 class="text-black01 font-weight-bold">Untrading offers a smarter way:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs) extend your earnings beyond each trade.</span></li>
            <li><span class="text-black01 font-weight-bold">A decentralized, fair profit-sharing model.</span></li>
            <li><span class="text-black01 font-weight-bold">A trading framework designed for sustainable, long-term wealth creation.</span></li>
        </ul>
        <p>Because the best investors don’t just play the game—they <span class="text-black01 font-weight-bold">build the system that wins.</span></p>
    </div>
</template>