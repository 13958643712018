<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">High-Frequency, High-Intensity—But Are You Actually Winning?</h3>
        <p >You trade fast. You thrive on volatility. You know the risks. But the truth is, you’re up against:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Market manipulation</span>—Big players hunt your stops.</li>
            <li><span class="text-black01 font-weight-bold">Psychological traps</span>—FOMO, overconfidence, and exhaustion.</li>
            <li><span class="text-black01 font-weight-bold">The limit of selling</span>—Your profit potential ends when you exit.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">Untrading rewrites the rules:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Your gains don’t end at selling.</span>Earn Future Rewards (FRs) as assets appreciate.</li>
            <li><span class="text-black01 font-weight-bold">No hidden fees, no middlemen.</span>Trade transparently, commission-free.</li>
            <li><span class="text-black01 font-weight-bold">Aligned incentives.</span>The community shares success instead of competing against each other.</li>
        </ul>
        <p >Untrading isn’t just a platform. It’s a mindset shift. Ready to break free?</p>
	</div>
</template>