<template>
	<div class="markdown prose">
        <p>You’re not here for quick flips. You invest in the long-term vision—weathering market cycles, ignoring short-term noise. But holding isn’t just about patience; it’s about endurance.</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Bear markets test your conviction.</span></li>
            <li><span class="text-black01 font-weight-bold">Market stagnation makes selling tempting.</span></li>
            <li><span class="text-black01 font-weight-bold">You watch others profit while you wait.</span></li>
        </ul>
        <p>With <span class="text-black01 font-weight-bold">Untrading</span>, you don’t just hold—you evolve:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Wrap your assets </span>(BTC → unBTC).</li>
            <li><span class="text-black01 font-weight-bold">Sell unBTC, but keep earning Future Rewards (FRs).</span></li>
            <li><span class="text-black01 font-weight-bold">Rebuy and repeat—staying in the game while unlocking new upside.</span></li>
        </ul>
        <p >Long-term vision should come with long-term benefits. <span class="text-black01 font-weight-bold">It’s time to Untrade.</span></p>
	</div>
</template>