<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Strategic Trading Works—Until the Market Doesn’t</h3>
        <p >Patience and precision should pay off. But no matter how carefully you trade, <span class="text-black01 font-weight-bold">market forces don’t care:</span></p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Liquidity grabs wipe out your stop losses.</span></li>
            <li><span class="text-black01 font-weight-bold">News events flip trends in seconds.</span></li>
            <li><span class="text-black01 font-weight-bold">Psychological traps lead even disciplined traders astray.</span></li>
        </ul>
        <h3 class="text-black01 font-weight-bold">Untrading flips the script:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Your profits don’t stop at selling. </span> Earn Future Rewards (FRs) post-sale.</li>
            <li><span class="text-black01 font-weight-bold">No commissions, no middlemen. </span>Keep more of what you earn.</li>
            <li><span class="text-black01 font-weight-bold">Trade on your terms, without market makers exploiting you.</span></li>
        </ul>
        <p >Because the best traders know: <span class="text-black01 font-weight-bold">long-term gains beat short-term games.</span></p>
	</div>
</template>