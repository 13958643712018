<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">You're a visionary investor, with your eyes set on the horizon, investing for the long haul.</h3>
        <h3 class="text-black01 font-weight-bold">The Upside:</h3>
        <ul class="ml-8 custom-ul">
            <li>Less need to constantly watch the market reduces stress.</li>
            <li>The potential for your investment to grow significantly over time.</li>
            <li>Your portfolio aligns with broader economic growth patterns.</li>
            <li>You retain full control of your assets, steering clear of counterparty risks.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">The Downside:</h3>
        <ul class="ml-8 custom-ul">
            <li>Your capital is less liquid, demanding patience for years.</li>
            <li>Market downturns can be psychologically challenging, testing your commitment.</li>
            <li>There are no immediate returns like dividends or interest to bolster your cash flow.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">The Hidden Risk:</h3>
        <p>Long-term investment is a marathon, not a sprint, with its own set of challenges:</p>
        <ul class="ml-8 custom-ul">
            <li>Bear markets can wear down even the most stoic investors emotionally.</li>
            <li>The temptation to sell during extended periods of market stagnation can lead to missing out on future recoveries.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">The Untrading Innovation:</h3>
        <p >Welcome to Untrading—where selling your assets is simply a new chapter in your investment story.</p>
        <ol class="ml-8 custom-ol">
            <li><span class="text-black01 font-weight-bold">Wrap Your Assets: </span>Turn your BTC or any other asset into wrapped Untrading tokens, like unBTC.</li>
            <li><span class="text-black01 font-weight-bold">Sell & Stay Connected: </span>Even after selling unBTC, you continue to earn Future Rewards (FRs) as the market appreciates.</li>
            <li><span class="text-black01 font-weight-bold">Rebuy & Repeat: </span>With the funds from your sale, re-enter the market by purchasing the asset again, keeping your investment active and dynamic.</li>
            <li><span class="text-black01 font-weight-bold">Maximize Upside: </span>This strategy of wrapping, selling, and buying back allows you to tap into both your initial investment strategy and the benefits of market cycles without truly exiting the investment world.</li>
        </ol>
        <p >With Untrading, you're not just a holder; you're an innovator who continues to play the long game with added advantages.</p>
	</div>
</template>