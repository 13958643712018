<template>
	<div class="markdown prose">
        <p>Your inspiration: Dalio. “Diversification, balance, and risk-adjusted returns create lasting wealth.”</p>
        <p >Untrading is built for investors like you who value collaboration, stability, and shared success. We align with your mindset by creating financial ecosystems where success is shared—not extracted:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Built-in Future Rewards (FRs): </span>Continue earning as assets appreciate.</li>
            <li><span class="text-black01 font-weight-bold">Decentralized & Transparent Profit-Sharing: </span>Fair systems that prioritize community.</li>
            <li><span class="text-black01 font-weight-bold">Stability & Growth: </span>A trading framework designed for long-term resilience.</li>
        </ul>
        <p >With Untrading, you don’t just build wealth—you build ecosystems that thrive together.</p>
    </div>
</template>