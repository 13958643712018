<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">You thrive on the market's pulse, a high-energy trader who loves the rush of quick gains.</h3>
        <h3 class="text-black01 font-weight-bold">The Upside:</h3>
        <ul class=" ml-8 custom-ul">
            <li>Potential for swift, high returns.</li>
            <li>Capitalize on market volatility for profit.</li>
            <li>Leverage for magnified gains.</li>
        </ul>
        <h3 class=" text-black01 font-weight-bold">The Downside:</h3>
        <ul class=" ml-8 custom-ul">
            <li>High risk; markets can turn against you rapidly.</li>
            <li>Demands relentless attention; one misstep can be costly.</li>
            <li>Prone to psychological traps like overconfidence and FOMO.</li>
        </ul>
        <h3 class=" text-black01 font-weight-bold">The Reality Check:</h3>
        <p class="">Charts are historical, not prophetic. Even with pattern recognition, you're at the mercy of market manipulators and unexpected shifts:</p>
        <ul class=" ml-8 custom-ul">
            <li>Stop hunts.</li>
            <li>Market manipulations.</li>
            <li>Sudden liquidity traps.</li>
        </ul>
        <h3 class=" text-black01 font-weight-bold">The Untrading Advantage:</h3>
        <p class="">Welcome to Untrading—where your gains don’t stop when you sell.</p>
        <ol class=" ml-8 custom-ol">
            <li><span class="text-black01 font-weight-bold">Selling is Just the Beginning: </span>Stay connected to your assets even after exiting.</li>
            <li><span class="text-black01 font-weight-bold">Commission-Free, Transparent, and Fair: </span>Trade without hidden fees or unfair practices.</li>
            <li><span class="text-black01 font-weight-bold">Earn Future Rewards (FRs): </span>Continue to benefit as assets appreciate post-sale.</li>
        </ol>
        <p>With Untrading, your success keeps growing—even after you’ve moved on.</p>
	</div>
</template>