<template>
	<div>
        <v-container class="pa-0 r-p-x-0 bg-black">
            <v-row no-gutters justify="center" align="center" class="py-100">
                <Customize></Customize>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Customize from './v2/components/Customize.vue';
export default {
    data(){
        return {

        }
    },
    components: { Customize },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme",'responsive'])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>