<template>
	<div class="bg-ffffff-212121 h-100">
        <article class="flex flex-col gap-2xl @md:gap-3xl">
            <div class="p-sm @md:p-md @md:h-[calc(100vh-64px-var(--header-h))] @md:py-0 relative flex flex-col justify-center">
                <div class="@container relative z-50 mx-auto w-full max-w-[768px] mb-280">
                    <div id="container" class="text-tertiary">
                        <div class="tex-base">
                            <div v-if="!hideTradingExperience">
                                <!-- 发送的消息 -->
                                <div id="ai-tradingExperience" v-if="step >= 2" class="d-flex r-justify-end">
                                    <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                                        <div v-if="tradingExperience == 'level-1'" >I Master Short-term Chart Patterns: High-energy, quick in-and-out trades!</div>
                                        <div v-if="tradingExperience == 'level-2'">I Navigate Daily/Weekly Trends: For the strategic, medium-term trend-following! </div>
                                        <div v-if="tradingExperience == 'level-3'">I'm a HODL/Diamond Hand: Visionary, long-term investing with patience and conviction!</div>
                                    </v-card>
                                </div>
                                <!-- 响应 -->
                                <div v-if="step >= 2" class="mt-6 @md:px-xs">
                                    <div v-if="tradingExperienceResponseLoading" class="mt-6">
                                        <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                                    </div>
                                    <div v-else>
                                        <component :is="currentTradingExperienceResponses[currentTradingExperienceResponsePage - 1]"></component>
                                        <div class="mt-4 mb-6">
                                            <ChatPage v-show="currentTradingExperienceResponses.length > 1" :page="currentTradingExperienceResponsePage" :total="currentTradingExperienceResponses.length" @updatePage="updateTradingExperienceResponsePage"></ChatPage>
                                            <v-menu open-on-hover location="bottom center">
                                                <template v-slot:activator="{ props }">
                                                    <v-btn v-bind="props" icon variant="text" size="28" @click="chooseRandomTradingExperienceResponse()">
                                                        <v-icon size="18">mdi mdi-autorenew</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-card class="pa-2 mt-2" color="#000000" rounded="lg" elevation="0">Generate again</v-card>
                                            </v-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 发送的消息 -->
                            <div id="ai-inspiration" v-if="step >= 3" class="d-flex r-justify-end">
                                <v-card rounded="xl" elevation="0" class="pa-4 w-66 text-base" color="f3f3f3-303030">
                                    <div v-if="inspiration == 'Soros'">Soros inspires me today - Tactical, high-risk, high-reward moves!</div>
                                    <div v-if="inspiration == 'Dalio'">Dalio inspires me today - Risk-adjusted, data-driven, diversified!</div>
                                    <div v-if="inspiration == 'Buffett'">Buffett inspires me today - Long-term wealth through conviction!</div>
                                </v-card>
                            </div>
                        </div>
                        <div v-if="step < 3" id="message-box" class="mt-6 pb-6 fixed bottom-0 bg-ffffff-212121">
                            <div class="@md:px-xs">
                                <div class="mt-6 text-black01 text-base">Now that you’ve explored your trading style and discovered how Untrading can help you win, who inspires your trading journey today?</div>
                            </div>
                            <div class="@md:rounded-3xl bg-ffffff-303030 shadow-md border-sm relative flex w-full cursor-text flex-col overflow-hidden rounded-2xl px-5 pb-[52px] pt-4 mt-6">
                                <div class="scroll-container">
                                    <div v-if="currentInspiration.name">{{ `${currentInspiration.name}: ${currentInspiration.message}` }}</div>
                                    <div v-else>&nbsp;</div>
                                </div>
                                <div class="absolute bottom-3 right-3 mt-auto flex justify-end">
                                    <v-btn icon size="32" color="primary" @click="setInspiration(currentInspiration?.name)">
                                        <v-icon color="white" size="18">mdi mdi-arrow-up</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <!-- 响应 -->
                        <div v-if="step >= 3" class="mt-6 @md:px-xs text-base">
                            <div v-if="inspirationResponseLoading" class="mt-6">
                                <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
                            </div>
                            <div v-else>
                                <component :is="currentInspirationResponses[currentInspirationResponsePage - 1]"></component>
                                <div class="mt-4 mb-6">
                                    <ChatPage v-show="currentInspirationResponses.length > 1" :page="currentInspirationResponsePage" :total="currentInspirationResponses.length" @updatePage="updateInspirationResponsePage"></ChatPage>
                                    <v-menu open-on-hover location="bottom center">
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" icon variant="text" size="28" @click="chooseRandomInspirationResponse()">
                                                <v-icon size="18">mdi mdi-autorenew</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-card class="pa-2 mt-2" color="#000000" rounded="lg" elevation="0">Generate again</v-card>
                                    </v-menu>
                                </div>
                            </div>
                            <div v-if="!inspirationResponseLoading" class="mt-10 markdown prose">
                                <h3 class="text-black01 font-weight-bold">Ready to Untrade?</h3>
                                <p class="font-italic">Get your customized Untrading solutions and start earning beyond selling.</p>
                                <div class="mt-6">
                                    <a :href="`https://testnet.untrading.org/uncryptos?inspiration=${inspiration}`">
                                        <button type="button" class="btn rounded-xl button_root button_variant-secondary button_size-small button_variant link_root" rel="noopener">
                                            <span class="px-4">Get My Untrading Plan<v-icon>mdi mdi-chevron-right</v-icon></span>
                                        </button>
                                    </a>
                                    <button type="button" class="btn-2 rounded-xl button_root button_variant-secondary button_size-small button_variant link_root ml-6" rel="noopener" @click="startOver()">
                                        <span class="px-4">Start Over<v-icon>mdi mdi-chevron-right</v-icon></span>
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import ChatPage from './components/ChatPage.vue';
// level1
import TradingExperienceLevel1Response1 from './data/trading/level1/1.vue';
import TradingExperienceLevel1Response2 from './data/trading/level1/2.vue';
import TradingExperienceLevel1Response3 from './data/trading/level1/3.vue';
import TradingExperienceLevel1Response4 from './data/trading/level1/4.vue';
import TradingExperienceLevel1Response5 from './data/trading/level1/5.vue';
import TradingExperienceLevel1Response6 from './data/trading/level1/6.vue';
import TradingExperienceLevel1Response7 from './data/trading/level1/7.vue';
import TradingExperienceLevel1Response8 from './data/trading/level1/8.vue';
import TradingExperienceLevel1Response9 from './data/trading/level1/9.vue';
import TradingExperienceLevel1Response10 from './data/trading/level1/10.vue';
import TradingExperienceLevel1Response11 from './data/trading/level1/11.vue';
import TradingExperienceLevel1Response12 from './data/trading/level1/12.vue';
import TradingExperienceLevel1Response13 from './data/trading/level1/13.vue';
import TradingExperienceLevel1Response14 from './data/trading/level1/14.vue';
import TradingExperienceLevel1Response15 from './data/trading/level1/15.vue';
// level2
import TradingExperienceLevel2Response1 from './data/trading/level2/1.vue';
import TradingExperienceLevel2Response2 from './data/trading/level2/2.vue';
import TradingExperienceLevel2Response3 from './data/trading/level2/3.vue';
import TradingExperienceLevel2Response4 from './data/trading/level2/4.vue';
import TradingExperienceLevel2Response5 from './data/trading/level2/5.vue';
import TradingExperienceLevel2Response6 from './data/trading/level2/6.vue';
import TradingExperienceLevel2Response7 from './data/trading/level2/7.vue';
import TradingExperienceLevel2Response8 from './data/trading/level2/8.vue';
import TradingExperienceLevel2Response9 from './data/trading/level2/9.vue';
import TradingExperienceLevel2Response10 from './data/trading/level2/10.vue';
import TradingExperienceLevel2Response11 from './data/trading/level2/11.vue';
import TradingExperienceLevel2Response12 from './data/trading/level2/12.vue';
import TradingExperienceLevel2Response13 from './data/trading/level2/13.vue';
import TradingExperienceLevel2Response14 from './data/trading/level2/14.vue';
import TradingExperienceLevel2Response15 from './data/trading/level2/15.vue';
// level3
import TradingExperienceLevel3Response1 from './data/trading/level3/1.vue';
import TradingExperienceLevel3Response2 from './data/trading/level3/2.vue';
import TradingExperienceLevel3Response3 from './data/trading/level3/3.vue';
import TradingExperienceLevel3Response4 from './data/trading/level3/4.vue';
import TradingExperienceLevel3Response5 from './data/trading/level3/5.vue';
import TradingExperienceLevel3Response6 from './data/trading/level3/6.vue';
import TradingExperienceLevel3Response7 from './data/trading/level3/7.vue';
import TradingExperienceLevel3Response8 from './data/trading/level3/8.vue';
import TradingExperienceLevel3Response9 from './data/trading/level3/9.vue';
import TradingExperienceLevel3Response10 from './data/trading/level3/10.vue';
import TradingExperienceLevel3Response11 from './data/trading/level3/11.vue';
import TradingExperienceLevel3Response12 from './data/trading/level3/12.vue';
import TradingExperienceLevel3Response13 from './data/trading/level3/13.vue';
import TradingExperienceLevel3Response14 from './data/trading/level3/14.vue';
import TradingExperienceLevel3Response15 from './data/trading/level3/15.vue';
// Soros
import inspirationSorosResponse1 from './data/inspiration/soros/1.vue';
import inspirationSorosResponse2 from './data/inspiration/soros/2.vue';
import inspirationSorosResponse3 from './data/inspiration/soros/3.vue';
import inspirationSorosResponse4 from './data/inspiration/soros/4.vue';
import inspirationSorosResponse5 from './data/inspiration/soros/5.vue';
import inspirationSorosResponse6 from './data/inspiration/soros/6.vue';
import inspirationSorosResponse7 from './data/inspiration/soros/7.vue';
import inspirationSorosResponse8 from './data/inspiration/soros/8.vue';
import inspirationSorosResponse9 from './data/inspiration/soros/9.vue';
import inspirationSorosResponse10 from './data/inspiration/soros/10.vue';
import inspirationSorosResponse11 from './data/inspiration/soros/11.vue';
import inspirationSorosResponse12 from './data/inspiration/soros/12.vue';
import inspirationSorosResponse13 from './data/inspiration/soros/13.vue';
import inspirationSorosResponse14 from './data/inspiration/soros/14.vue';
import inspirationSorosResponse15 from './data/inspiration/soros/15.vue';
// Dalio
import inspirationDalioResponse1 from './data/inspiration/dalio/1.vue';
import inspirationDalioResponse2 from './data/inspiration/dalio/2.vue';
import inspirationDalioResponse3 from './data/inspiration/dalio/3.vue';
import inspirationDalioResponse4 from './data/inspiration/dalio/4.vue';
import inspirationDalioResponse5 from './data/inspiration/dalio/5.vue';
import inspirationDalioResponse6 from './data/inspiration/dalio/6.vue';
import inspirationDalioResponse7 from './data/inspiration/dalio/7.vue';
import inspirationDalioResponse8 from './data/inspiration/dalio/8.vue';
import inspirationDalioResponse9 from './data/inspiration/dalio/9.vue';
import inspirationDalioResponse10 from './data/inspiration/dalio/10.vue';
import inspirationDalioResponse11 from './data/inspiration/dalio/11.vue';
import inspirationDalioResponse12 from './data/inspiration/dalio/12.vue';
import inspirationDalioResponse13 from './data/inspiration/dalio/13.vue';
import inspirationDalioResponse14 from './data/inspiration/dalio/14.vue';
import inspirationDalioResponse15 from './data/inspiration/dalio/15.vue';
// Buffett
import inspirationBuffettResponse1 from './data/inspiration/buffett/1.vue';
import inspirationBuffettResponse2 from './data/inspiration/buffett/2.vue';
import inspirationBuffettResponse3 from './data/inspiration/buffett/3.vue';
import inspirationBuffettResponse4 from './data/inspiration/buffett/4.vue';
import inspirationBuffettResponse5 from './data/inspiration/buffett/5.vue';
import inspirationBuffettResponse6 from './data/inspiration/buffett/6.vue';
import inspirationBuffettResponse7 from './data/inspiration/buffett/7.vue';
import inspirationBuffettResponse8 from './data/inspiration/buffett/8.vue';
import inspirationBuffettResponse9 from './data/inspiration/buffett/9.vue';
import inspirationBuffettResponse10 from './data/inspiration/buffett/10.vue';
import inspirationBuffettResponse11 from './data/inspiration/buffett/11.vue';
import inspirationBuffettResponse12 from './data/inspiration/buffett/12.vue';
import inspirationBuffettResponse13 from './data/inspiration/buffett/13.vue';
import inspirationBuffettResponse14 from './data/inspiration/buffett/14.vue';
import inspirationBuffettResponse15 from './data/inspiration/buffett/15.vue';
export default {
    data(){
        return {
            // 步骤
            step: 2,
            hideTradingExperience: false,
            // 期望人物集合
            inspirations: [
                { name: 'Soros', message: 'Tactical, high-risk, high-reward moves' },
                { name: 'Dalio', message: 'Risk-adjusted, data-driven, diversified' },
                { name: 'Buffett', message: 'Long-term wealth through conviction' }
            ],
            // 交易经验响应加载中
            tradingExperienceResponseLoading: false,
            // 当前交易经验响应页码
            currentTradingExperienceResponsePage: 1,
            // 当前交易经验响应集合
            currentTradingExperienceResponses: [],
            // 交易经验响应集合
            tradingExperienceLevel1Responses: [TradingExperienceLevel1Response1, TradingExperienceLevel1Response2, TradingExperienceLevel1Response3, TradingExperienceLevel1Response4, TradingExperienceLevel1Response5, TradingExperienceLevel1Response6, TradingExperienceLevel1Response7, TradingExperienceLevel1Response8, TradingExperienceLevel1Response9, TradingExperienceLevel1Response10, TradingExperienceLevel1Response11, TradingExperienceLevel1Response12, TradingExperienceLevel1Response13, TradingExperienceLevel1Response14, TradingExperienceLevel1Response15],
            tradingExperienceLevel2Responses: [TradingExperienceLevel2Response1, TradingExperienceLevel2Response2, TradingExperienceLevel2Response3, TradingExperienceLevel2Response4, TradingExperienceLevel2Response5, TradingExperienceLevel2Response6, TradingExperienceLevel2Response7, TradingExperienceLevel2Response8, TradingExperienceLevel2Response9, TradingExperienceLevel2Response10, TradingExperienceLevel2Response11, TradingExperienceLevel2Response12, TradingExperienceLevel2Response13, TradingExperienceLevel2Response14, TradingExperienceLevel2Response15],
            tradingExperienceLevel3Responses: [TradingExperienceLevel3Response1, TradingExperienceLevel3Response2, TradingExperienceLevel3Response3, TradingExperienceLevel3Response4, TradingExperienceLevel3Response5, TradingExperienceLevel3Response6, TradingExperienceLevel3Response7, TradingExperienceLevel3Response8, TradingExperienceLevel3Response9, TradingExperienceLevel3Response10, TradingExperienceLevel3Response11, TradingExperienceLevel3Response12, TradingExperienceLevel3Response13, TradingExperienceLevel3Response14, TradingExperienceLevel3Response15],
            // 当前期望人物
            currentInspiration: {},
            // 期望人物响应加载中
            inspirationResponseLoading: false,
            // 当前期望人物响应页码
            currentInspirationResponsePage: 1,
            // 当前期望人物响应集合
            currentInspirationResponses: [],
            // 期望人物响应集合
            inspirationSorosResponses: [inspirationSorosResponse1, inspirationSorosResponse2, inspirationSorosResponse3, inspirationSorosResponse4, inspirationSorosResponse5, inspirationSorosResponse6, inspirationSorosResponse7, inspirationSorosResponse8, inspirationSorosResponse9, inspirationSorosResponse10, inspirationSorosResponse11, inspirationSorosResponse12, inspirationSorosResponse13, inspirationSorosResponse14, inspirationSorosResponse15],
            inspirationDalioResponses: [inspirationDalioResponse1, inspirationDalioResponse2, inspirationDalioResponse3, inspirationDalioResponse4, inspirationDalioResponse5, inspirationDalioResponse6, inspirationDalioResponse7, inspirationDalioResponse8, inspirationDalioResponse9, inspirationDalioResponse10, inspirationDalioResponse11, inspirationDalioResponse12, inspirationDalioResponse13, inspirationDalioResponse14, inspirationDalioResponse15],
            inspirationBuffettResponses: [inspirationBuffettResponse1, inspirationBuffettResponse2, inspirationBuffettResponse3, inspirationBuffettResponse4, inspirationBuffettResponse5, inspirationBuffettResponse6, inspirationBuffettResponse7, inspirationBuffettResponse8, inspirationBuffettResponse9, inspirationBuffettResponse10, inspirationBuffettResponse11, inspirationBuffettResponse12, inspirationBuffettResponse13, inspirationBuffettResponse14, inspirationBuffettResponse15],
        }
    },
    components: { ChatPage, inspirationBuffettResponse1, inspirationBuffettResponse2, inspirationBuffettResponse3, inspirationBuffettResponse4, inspirationBuffettResponse5, inspirationBuffettResponse6, inspirationBuffettResponse7, inspirationBuffettResponse8, inspirationBuffettResponse9, inspirationBuffettResponse10, inspirationBuffettResponse11, inspirationBuffettResponse12, inspirationBuffettResponse13, inspirationBuffettResponse14, inspirationBuffettResponse15 },
    created(){

    },
    mounted(){
        // 加载交易经验
        this.loadTradingExperience();
        // 加载交易灵感
        this.loadInspiration();
        // 更新交易灵感
        this.updateCurrentInspiration();
        // 监听消息框宽度
        this.watchMessageBoxWidth();
    },
    computed: {
        ...mapGetters(['tradingExperience', 'inspiration'])
    },
    watch:{
        
    },
    methods: {
        // 监听消息框宽度
        watchMessageBoxWidth() {
            // 获取父级主体元素
            const parent = document.querySelector('#container');
            // 获取消息框元素
            const messageBox = document.querySelector('#message-box');
            // 设置消息框宽度
            messageBox.style.width = `${parent.offsetWidth}px`;
            // 监听窗口大小变化重新设置消息框宽度
            window.addEventListener('resize', () => {
                messageBox.style.width = `${parent.offsetWidth}px`;
            });
        },
        // 加载交易经验
        loadTradingExperience() {
            if (!this.tradingExperience) {
                this.$router.push('/');
            }
            // 选择随机的交易经验响应
            this.chooseRandomTradingExperienceResponse();
        },
        // 加载交易灵感
        loadInspiration() {
            if (this.inspiration) {
                // 隐藏交易经验
                this.hideTradingExperience = true;
                // 设置交易灵感
                this.setInspiration(this.inspiration);
            }
        },
        // 选择随机的交易经验响应
        async chooseRandomTradingExperienceResponse() {
            this.tradingExperienceResponseLoading = true;
            await this.sleep(3000);
            let responses = [];
            switch (this.tradingExperience) {
                case 'level-1': responses = this.tradingExperienceLevel1Responses; break;
                case 'level-2': responses = this.tradingExperienceLevel2Responses; break;
                case 'level-3': responses = this.tradingExperienceLevel3Responses; break;
            }
            // 生成随机数
            const randomIndex = Math.floor(Math.random() * responses.length);
            // 追加到最后
            this.currentTradingExperienceResponses.push(responses[randomIndex]);
            // 默认页码为最后一个
            this.currentTradingExperienceResponsePage = this.currentTradingExperienceResponses.length;
            this.tradingExperienceResponseLoading = false;
        },
        // 更新交易经验响应页码
        updateTradingExperienceResponsePage(page) {
            this.currentTradingExperienceResponsePage = page;
        },
        // 更新交易灵感
        updateCurrentInspiration() {
            let index = 0;
            this.currentInspiration = this.inspirations[0];
            setInterval(async () => {
                index = (index + 1) % this.inspirations.length;
                this.currentInspiration = {};
                await this.sleep(200);
                this.currentInspiration = this.inspirations[index];
            }, 6000);
        },
        // 设置交易灵感
        setInspiration(inspiration) {
            this.step = 3;
            this.$store.dispatch("inspirationHandler", inspiration);
            // 选择随机的期望人物响应
            this.chooseRandomInspirationResponse();
            // setTimeout(() => {
            //     this.loading = false;
            //     this.$router.push(`/uncryptos?inspiration=${inspiration}`);
            // }, 15 * 1000);
        },
        // 选择随机的期望人物响应
        async chooseRandomInspirationResponse() {
            this.inspirationResponseLoading = true;
            await this.sleep(3000);
            let responses = [];
            switch (this.inspiration) {
                case 'Soros': responses = this.inspirationSorosResponses; break;
                case 'Dalio': responses = this.inspirationDalioResponses; break;
                case 'Buffett': responses = this.inspirationBuffettResponses; break;
            }
            // 生成随机数
            const randomIndex = Math.floor(Math.random() * responses.length);
            // 追加到最后
            this.currentInspirationResponses.push(responses[randomIndex]);
            // 默认页码为最后一个
            this.currentInspirationResponsePage = this.currentInspirationResponses.length;
            this.inspirationResponseLoading = false;
        },
        // 更新交易经验响应页码
        updateInspirationResponsePage(page) {
            this.currentInspirationResponsePage = page;
        },
        // 睡眠
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        // 重新开始
        startOver() {
            this.step = 1;
            this.$store.dispatch("tradingExperienceHandler", null);
            this.$store.dispatch("inspirationHandler", null);
            localStorage.removeItem('untrading-welcome');
            window.location.href = '/';
        }
    }
}
</script>
<style scoped>
/* 固定在底部的输入框容器 */
.input-container {
      position: absolute;
      bottom: 0;
    }
</style>