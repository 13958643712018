<template>
	<div class="markdown prose">
        <p>The charts tell you what happened yesterday—but they don’t predict tomorrow. Market manipulators set traps, liquidity dries up in an instant, and just when you think you’re ahead, the rug gets pulled.</p>
        <p >You play the game well, but the game is rigged.</p>
        <p >At Untrading, we built a different way:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Selling isn’t the end.</span> It’s just the beginning.</li>
            <li><span class="text-black01 font-weight-bold">No fees, no tricks, no unfair edges.</span>Trade in a system designed for traders, not against them.</li>
            <li><span class="text-black01 font-weight-bold">Future Rewards (FRs) keep you connected to your assets.</span>Your success continues even after you sell.</li>
        </ul>
        <p >Because the best traders don’t just trade—they <span class="text-black01 font-weight-bold">Untrade.</span></p>
	</div>
</template>