<template>
	<div class="lexend-deca">
        <div v-if="$config.env == 'testnet'">
            <div class="body-h5 text-primaryGrey text-center">No leaderboards on testnet!</div>
        </div>
        <div v-else-if="loading">
            <v-skeleton-loader color="transparent" elevation="0" rounded="0" type="list-item-avatar-two-line, divider" v-for="i in 100" :key="i"></v-skeleton-loader>
        </div>
        <div v-else-if="!loading && userRewardLeaderboards.length == 0">
            <NoDataAvailable></NoDataAvailable>
        </div>
        <div v-else v-for="(leaderboard, index) in userRewardLeaderboards" :key="leaderboard.userId">
            <v-card elevation="0" rounded="0" :variant="isMe(leaderboard.userId) ? 'outlined' : 'text'">
                <v-row no-gutters align="center">
                    <v-col cols="1">
                        <span class="body-p-medium text-primaryGrey" :class="isMe(leaderboard.userId) ? 'ml-3' : ''">{{ leaderboard?.ranking }}</span>
                    </v-col>
                    <v-col cols="1" class="mt-2">
                        <span class="fs-30" v-if="leaderboard?.ranking == 1">🥇</span>
                        <span class="fs-30" v-if="leaderboard?.ranking == 2">🥈</span>
                        <span class="fs-30" v-if="leaderboard?.ranking == 3">🥉</span>
                    </v-col>
                    <v-col cols="6">
                        <v-list v-model:opened="openedGroups" bg-color="transparent" class="py-0">
                            <v-list-item class="px-0">
                                <template v-slot:prepend>
                                    <UserAvatar :avatar="leaderboard?.user?.avatar" :username="leaderboard?.user?.username" :size="32" class="mr-4"></UserAvatar>
                                </template>
                                <template v-slot:title>
                                    <span class="mr-2">{{ leaderboard?.user?.name }}</span>
                                </template>
                                <template v-slot:subtitle>
                                    <span class="mr-2">{{ $tools.privacyField(leaderboard?.user?.wallet) }}</span>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="2">
                        <span v-if="isMe(leaderboard.userId)" class="bg-primary text-white pa-1">This is you</span>
                    </v-col>
                    <v-col cols="2" align="end">
                        <span class="body-p-medium text-primaryGrey" :class="isMe(leaderboard.userId) ? 'mr-3' : ''">{{ $tools.convertToAbbr(leaderboard?.balance) }}</span>
                    </v-col>
                    <v-col cols="12">
                        <v-divider v-show="index != userRewardLeaderboards.length - 1"></v-divider>
                    </v-col>
                </v-row>
            </v-card>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import UserRewardKingdomAPI from '@/api/user-reward-kingdom.js';
export default {
    data(){
        return {
            // 加载中
            loading: false,
            // 用户奖励排行榜
            userRewardLeaderboards: []
        }
    },
    components: {  },
    created(){

    },
    mounted(){
        // 分页查询用户奖励排行榜列表
        this.getLeaderboardsByPage();
    },
    computed: {
        ...mapGetters(['user']),
    },
    watch:{

    },
    methods: {
        // 分页查询用户奖励排行榜列表
        async getLeaderboardsByPage() {
            // 加载中
            this.loading = true;
            this.userRewardLeaderboards = [];
            let res = await UserRewardKingdomAPI.getLeaderboardsByPage(1, 100);
            let data = res.data;
            if(data.code == 200) {
                this.userRewardLeaderboards = data.data.records;
            }
            // 加载结束
            this.loading = false;
        },
        // 判断是否是自己
        isMe(userId) {
            return userId == this.user?.id;
        }

    }
}
</script>
<style scoped>

</style>