<template>
	<div>
        <v-container class="pa-0 r-p-x-0">
            <v-row no-gutters justify="center" align="center" class="py-100 bg-black">
                <FeeConflict></FeeConflict>
            </v-row>
            <v-row no-gutters justify="center" align="center" class="py-100 bg-black" id="pe">
                <ProfitExpectancy></ProfitExpectancy>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import FeeConflict from './v2/components/FeeConflict.vue';
import ProfitExpectancy from './v2/components/ProfitExpectancy.vue';
export default {
    data(){
        return {

        }
    },
    components: { FeeConflict,ProfitExpectancy},
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme",'responsive'])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>