<template>
	<div class="markdown prose">
        <p>Strategic traders know: patience pays. But even the most calculated plans face market manipulation and sudden reversals.</p>
        <p >Discover Untrading—where strategy meets sustainability.</p>
        <ul class="ml-8 custom-ul">
            <li>Keep earning after you sell</li>
            <li>No fees, no predictions needed</li>
            <li>Future Rewards on every trade</li>
        </ul>
		<p >Trade your way, without the usual pitfalls. Because smart traders deserve smart solutions.</p>
        <p >Welcome to trading on your terms.</p>
	</div>
</template>