<template>
	<div class="markdown prose">
        <p>TACTICAL ALPHA GENERATION PROTOCOL</p>
        <p >System Architecture:</p>
		<ul class="ml-8 custom-ul">
            <li>Zero Friction Implementation</li>
            <li>Asymmetric Return Generation</li>
            <li>Position Size Optimization</li>
            <li>Edge Capture Maximization</li>
        </ul>
        <p >Execution Framework:</p>
		<ol class="ml-8 custom-ol">
            <li>
                <span>Position Initiation</span>
                <ul class="ml-8 custom-circle-ul">
                    <li>Frictionless Entry</li>
                    <li>Size Optimization</li>
                    <li>Risk Parameter Setting</li>
                </ul>
            </li>
            <li>
                <span>Alpha Capture</span>
                <ul class="ml-8 custom-circle-ul">
                    <li>Edge Recognition</li>
                    <li>Position Scaling</li>
                    <li>Return Maximization</li>
                </ul>
            </li>
            <li>
                <span>Value Extraction</span>
                <ul class="ml-8 custom-circle-ul">
                    <li>Continued Participation</li>
                    <li>Multiple Return Streams</li>
                    <li>Compound Edge Creation</li>
                </ul>
            </li>
        </ol>
        <p >Transform tactical opportunities into sustained alpha generation through systematic advantage creation.</p>
        <p >Where being right matters less than being right enough.</p>
	</div>
</template>