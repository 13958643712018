<template>
	<div class="markdown prose">
        <p>For Those Who See Further</p>
        <p >You've always known—the real gains come to those who wait. But what if waiting was just the beginning?</p>
        <p >Imagine never truly leaving your position. Imagine selling without saying goodbye. Imagine your diamond hands creating endless value streams.</p>
        <p >Untrading makes it real:</p>
		<ul class="ml-8 custom-ul">
            <li>Wrap your assets into something greater</li>
            <li>Sell while staying connected</li>
            <li>Earn rewards from future gains</li>
            <li>Build layers of value</li>
        </ul>
        <p >Because true visionaries deserve more than just holding. They deserve perpetual growth.</p>
        <p >Welcome to the future of long-term value creation. Where diamond hands become eternal profit engines.</p>
	</div>
</template>