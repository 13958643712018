<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">High-energy trading? Here's the catch:</h3>
        <ul class="ml-8 custom-ul">
            <li>Fast gains, but high risk.</li>
            <li>Charts don't predict; beware manipulators.</li>
        </ul>
        <h3 class="text-black01 font-weight-bold">Untrading changes the game:</h3>
        <ul class="ml-8 custom-ul">
            <li>Earn beyond the sale.</li>
            <li>No hidden fees.</li>
            <li>Gain from future asset value.</li>
        </ul>
        <p >Your wealth keeps growing with Untrading.</p>
	</div>
</template>