<template>
	<div class="markdown prose">
        <p>Strategic Trading Evolved</p>
        <p >You know the feeling: Perfect analysis. Patient execution. Precise entry. Then the market shifts, and your carefully planned position unravels.</p>
        <p >What if your strategy could work beyond the sell button?</p>
        <p >Untrading delivers:</p>
        <ul class="ml-8 custom-ul">
            <li>Continuous returns post-exit</li>
            <li>Protection from market games</li>
            <li>Freedom from emotional pressure</li>
        </ul>
		<p >Because strategic traders deserve more than just moments of validation. You deserve sustained success.</p>
        <p >Join the evolution of strategic trading, where your analysis creates lasting value.</p>
        <p >Trade smarter. Earn longer. Succeed consistently.</p>
        <p >Welcome to Untrading.</p>
	</div>
</template>