<template>
	<div class="markdown prose">
        <h3 class="text-black01 font-weight-bold">Maximization Over Perfection—That’s the Soros Mindset</h3>
        <p >The best traders know: <span class="text-black01 font-weight-bold">being right isn’t the goal—winning big when you are is.</span></p>
        <p >But traditional markets work against you:</p>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">Commissions drain profits.</span></li>
            <li><span class="text-black01 font-weight-bold">Predictions force reliance on unreliable models.</span></li>
            <li><span class="text-black01 font-weight-bold">Market inefficiencies benefit institutions, not you.</span></li>
        </ul>
        <h3 class="text-black01 font-weight-bold">Untrading changes the rules:</h3>
        <ul class="ml-8 custom-ul">
            <li><span class="text-black01 font-weight-bold">No commissions, no friction—keep what you earn.</span></li>
            <li><span class="text-black01 font-weight-bold">No need to predict—capitalize on asymmetric moves.</span></li>
            <li><span class="text-black01 font-weight-bold">Pure efficiency—where your best trades aren’t limited by system flaws.</span></li>
        </ul>
        <p >Trade like an insider, without the insider disadvantages.</p>
    </div>
</template>